import { withTheme } from '@material-ui/core/styles';

/**
 * Makes a component themeable for material ui when the app runs as cms.
 * @param {React.Component} component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function themeableForCms(component: any) {
	const result = withTheme(component);
	for (let property in component) {
		// eslint-disable-next-line no-prototype-builtins
		if (component.hasOwnProperty(property)) {
			result[property] = component[property];
		}
	}
	return result;
}
