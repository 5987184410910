import React from 'react';
import { default as TeaserBoard, definedLayoutTypes } from '../TeaserBoard/TeaserBoard';
import classNames from 'classnames';
import styles from './TeaserBoardEditor.scss';
import { ArticleList } from '../../components.webcms';
import TeaserBoardDragLayer from './TeaserBoardDragLayer';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import SvgPhone from './device-phone.svg';
import SvgDesktop from './device-desktop.svg';
import SvgTabletLandscape from './device-tablet-landscape.svg';
import SvgTabletPortrait from './device-tablet-portrait.svg';

import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

class TeaserBoardEditor extends React.Component {
	state = { device: 'desktop', isSuccessSnackBarOpen: false, isErrorSnackBarOpen: false };

	handleLayoutChange = newLayoutType => this.props.onLayoutTypeChange(newLayoutType);
	handleDeviceChange = newDevice => this.setState({ device: newDevice });
	handleTileChange = (index, newArticle) => {
		let newTeasers = this.props.teasers.slice();
		let existingIndex = -1;
		for (let i = 0; i < newTeasers.length; i++) {
			if (newTeasers[i] && newTeasers[i].id === newArticle.id) {
				existingIndex = i;
				break;
			}
		}

		if (existingIndex === -1) {
			newTeasers[index] = newArticle;
		} else {
			let swapArticle = newTeasers[index];
			newTeasers[existingIndex] = swapArticle;
			newTeasers[index] = newArticle;
		}

		this.props.onTeasersChange(newTeasers);
	};

	componentDidUpdate(prevProps) {
		if (this.props.success && !prevProps.success) this.setState({ isSuccessSnackBarOpen: true });
		if (this.props.errors && this.props.errors.length && (this.props.errors || []).length !== (prevProps.errors || []).length)
			this.setState({ isErrorSnackBarOpen: true });
	}

	render() {
		const previewWidths = {
			desktop: '100%',
			'tablet-landscape': '70%',
			'tablet-portrait': '40%',
			phone: '25%',
		};

		const deviceButtonWidths = {
			desktop: '100%',
			'tablet-landscape': '80%',
			'tablet-portrait': '45%',
			phone: '35%',
		};

		return (
			<div className={classNames(styles.editorWrapper)}>
				<TeaserBoardDragLayer />
				{this.props.errors && this.props.errors.length && (
					<mui.Snackbar
						open={this.state.isErrorSnackBarOpen}
						message={this.props.errors[this.props.errors.length - 1].toString()}
						autoHideDuration={3000}
						onClose={() => {
							this.setState({ isErrorSnackBarOpen: false });
						}}
						style={{ backgroundColor: '#a55' }}
					/>
				)}
				{this.props.success && (
					<mui.Snackbar
						open={this.state.isSuccessSnackBarOpen}
						message={this.props.success}
						autoHideDuration={3000}
						onClose={() => {
							this.setState({ isSuccessSnackBarOpen: false });
						}}
						style={{ backgroundColor: '#5a5' }}
					/>
				)}
				<div className={classNames(styles.articleList)}>
					<ArticleList
						articles={this.props.articles}
						detailLevel={0}
						itemsDraggable
						loadMore={this.props.loadMore}
						searchArticle={this.props.searchArticle}
						searchResults={this.props.searchResults}
						statusFilter={['published']}
					/>
				</div>
				<div className={classNames(styles.preview, styles[this.state.device])}>
					<TeaserBoard
						onChange={this.handleTileChange}
						layoutType={this.props.layoutType}
						device={this.state.device}
						teasers={this.props.teasers}
						style={{ width: previewWidths[this.state.device], margin: '0 auto' }}
					/>
				</div>
				<div className={classNames(styles.layoutSelection)}>
					<div className={styles.deviceSelection}>
						{['desktop', 'tablet-landscape', 'tablet-portrait', 'phone'].map(device => {
							let DeviceIcon;

							switch (device) {
								case 'desktop':
									DeviceIcon = SvgDesktop;
									break;
								case 'phone':
									DeviceIcon = SvgPhone;
									break;
								case 'tablet-landscape':
									DeviceIcon = SvgTabletLandscape;
									break;
								case 'tablet-portrait':
									DeviceIcon = SvgTabletPortrait;
									break;
								default:
									DeviceIcon = SvgDesktop;
							}

							return (
								<mui.Button
									key={device}
									color={device === this.state.device ? 'secondary' : 'default'}
									onClick={this.handleDeviceChange.bind(null, device)}
									className={classNames(device === this.state.device ? styles.selectedDeviceButton : '')}
									style={{
										minWidth: '25%',
										width: '25%',
										maxWidth: '25%',
										padding: '0.5rem 0',
										borderRight: '1px solid #f4f4f4',
									}}
								>
									<DeviceIcon />
								</mui.Button>
							);
						})}
					</div>
					<div>
						{definedLayoutTypes.map(layoutType => (
							<mui.Button
								variant="contained"
								key={layoutType}
								onClick={this.handleLayoutChange.bind(this, layoutType)}
								color={layoutType === this.props.layoutType ? 'primary' : 'default'}
								style={{
									display: 'block',
									width: deviceButtonWidths[this.state.device],
									minWidth: 'auto',
									margin: '0 auto 1rem auto',
									padding: 0,
									height: 'auto',
								}}
							>
								<div className={classNames(styles.layout)}>
									<TeaserBoard layoutType={layoutType} device={this.state.device} teasers={[]} />
								</div>
							</mui.Button>
						))}
					</div>

					<div>
						<mui.Button
							variant="contained"
							onClick={this.props.onSave}
							color="primary"
							disabled={!(this.props.changed && !this.props.saving && !this.props.loading)}
							fullWidth={true}
							style={{
								margin: '0.5rem auto 0 auto',
							}}
						>
							{this.props.saving ? 'Speichert...' : this.props.changed ? 'Speichern' : 'Gespeichert'}
						</mui.Button>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default DragDropContext(HTML5Backend)(withTheme(TeaserBoardEditor));
