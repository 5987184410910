import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { User } from '@sep/br-auth-client';
import React from 'react';
import { FormControl, InputLabel, Input, withStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
	createStyles({
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(),
		},
		submit: {
			marginTop: theme.spacing(3),
		},
		twoFactorInputContainer: {
			display: 'grid',
			gridTemplateRows: '[otp] 1fr [actions] 1fr',
			gridRowGap: '0.5em',
		},
		infoText: {
			color: '#888',
			fontSize: '1.3em',
		},
		actionContainer: {
			display: 'grid',
			alignItems: 'center',
			gridTemplateColumns: 'auto auto',
			gridColumnGap: '1em',
		},
		logoutInputContainer: {
			display: 'grid',
			alignItems: 'center',
			gridRowGap: '1em',
			padding: '1.5em',
		},
	});

/**
 * Shows up when the user is already logged in and offers the possibility to logout.
 */
export const LogoutContainer = withStyles(styles)(
	({ user, logout, classes }: { user: User; logout: () => void; classes: Record<string, string> }) => (
		<div className={classes.logoutInputContainer}>
			<div>
				{user.firstname} {user.lastname} ist bereits am System angemeldet.
			</div>
			<div>
				<Button variant="contained" color="primary" onClick={logout}>
					Abmelden
				</Button>
			</div>
		</div>
	)
);

/**
 * Shows up when the user is already logged in and offers the possibility to logout.
 */
export const WaitForTokenContainer = withStyles(styles)(() => <div>Du wirst eingeloggt und dann weitergeleitet...</div>);

/**
 * Form shown to enter the login details.
 */
export const LoginContainer = withStyles(styles)(
	({
		onLoginSubmit,
		onLoginChange,
		isLoginDisabled,
		classes,
	}: {
		onLoginSubmit: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
		onLoginChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
		isLoginDisabled: boolean;
		classes: Record<string, string>;
	}) => (
		<form className={classes.form}>
			<FormControl margin="normal" required fullWidth>
				<InputLabel htmlFor="username">BR Benutzername</InputLabel>
				<Input id="username" name="username" autoFocus onChange={onLoginChange} />
			</FormControl>
			<FormControl margin="normal" required fullWidth>
				<InputLabel htmlFor="password">Passwort</InputLabel>
				<Input name="password" type="password" id="password" autoComplete="current-password" onChange={onLoginChange} />
			</FormControl>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
				onClick={onLoginSubmit}
				disabled={isLoginDisabled}
			>
				Login
			</Button>
		</form>
	)
);

/**
 * Form shown to enter the two factor code.
 */
export const TwoFactorContainer = withStyles(styles)(
	({
		onTwoFactorChange,
		onTwoFactorCancel,
		onTwoFactorSubmit,
		isTwoFactorDisabled,
		classes,
	}: {
		onTwoFactorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
		onTwoFactorCancel: () => void;
		onTwoFactorSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
		isTwoFactorDisabled: boolean;
		classes: Record<string, string>;
	}) => (
		<form className={classes.form}>
			<div className={classes.twoFactorInputContainer}>
				<span className={classes.infoText}>
					Du hast ein sechsstelliges Passwort per E-Mail erhalten. Dieses musst Du in das unten stehende Feld einfügen oder manuell
					eintragen, um Zugriff auf den Newsroom zu erhalten. Solltest Du keine E-Mail erhalten haben, gehe zurück und melde Dich bitte
					erneut an.
				</span>
				<TextField id="otp" onChange={onTwoFactorChange} label="Login Code" type="text" />
				<div className={classes.actionContainer}>
					<Button variant="contained" onClick={onTwoFactorCancel}>
						Zurück
					</Button>
					<Button variant="contained" onClick={onTwoFactorSubmit} disabled={isTwoFactorDisabled} color="primary" type="submit">
						Bestätigen
					</Button>
				</div>
			</div>
		</form>
	)
);
