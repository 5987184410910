import React from 'react';
import ArticleListItem from './ArticleListItem.js';
import DraggableArticleListItem from './DraggableArticleListItem.js';
import ClickableArticleListItem from './ClickableArticleListItem.js';
import classNames from 'classnames';
import styles from './ArticleList.scss';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

const statusFilter = {
	names: ['Alle Artikel (außer Archiv)', 'Entwürfe', 'Abgenommene', 'Veröffentlichte', 'Archiv'],
	values: [['draft', 'review', 'published'], ['draft'], ['review'], ['published'], ['depublished']],
};

class ArticleList extends React.Component {
	static defaultProps = {
		statusFilter: statusFilter.values[0],
	};

	state = {
		filter: null,
		statusFilter: this.props.statusFilter,
		searchTerm: '',
		isSnackbarOpen: false,
	};

	handleFilterChange = event => {
		this.setState({ searchTerm: event.target.value || '' });
		this.props.searchArticle(event.target.value, this.state.statusFilter);
	};

	handleStatusFilterChange = event => {
		const newStatus = statusFilter.values[event.target.value];
		this.setState({ statusFilter: newStatus });
		if (this.state.searchTerm.length > 0) {
			this.props.searchArticle(this.state.searchTerm, newStatus);
		}
	};

	UNSAFE_componentWillUpdate(nextProps) {
		const snackbarOpen = Boolean(
			nextProps.errors && nextProps.errors.length > 0 && nextProps.errors.length !== (this.props.errors || []).length
		);
		if (this.state.isSnackbarOpen !== snackbarOpen) this.setState({ isSnackbarOpen: snackbarOpen });
	}

	componentDidMount() {
		if (this.props.errors && this.props.errors.length) {
			this.setState({ isSnackbarOpen: true });
		}
	}

	render() {
		const detailLevelNames = ['low', 'medium', 'high'];

		let ListItemType = this.props.itemsDraggable
			? DraggableArticleListItem
			: this.props.itemsClickable
			? ClickableArticleListItem
			: ArticleListItem;

		let filteredArticles = this.props.articles.filter(
			article => this.state.statusFilter.indexOf(article.attributes.metadata.doc.status) !== -1
		);
		filteredArticles = filteredArticles.sort((a1, a2) => ((a1.updatedAt || a1.createdAt) < (a2.updatedAt || a2.createdAt) ? 1 : -1));

		if (this.state.searchTerm.length > 0) {
			filteredArticles = this.props.searchResults;
		}

		return (
			<div className={styles.articleList}>
				<mui.Snackbar
					open={this.state.isSnackbarOpen}
					message={this.props.errors && this.props.errors.length ? [this.props.errors.length - 1].toString() : ''}
					autoHideDuration={3000}
					onClose={() => {
						this.setState({ isSnackbarOpen: false });
					}}
					style={{ backgroundColor: '#a55' }}
				/>
				<div className={classNames(styles.filtering)}>
					{this.props.showStatusFilter && (
						<mui.Select
							className={classNames(styles.statusFilter)}
							onChange={this.handleStatusFilterChange}
							value={statusFilter.values.indexOf(this.state.statusFilter)}
						>
							{statusFilter.names.map((stat, i) => (
								<mui.MenuItem key={i} value={i}>
									{stat}
								</mui.MenuItem>
							))}
						</mui.Select>
					)}
					<mui.TextField className={classNames(styles.search)} placeholder="Artikelsuche..." onChange={this.handleFilterChange} />
				</div>
				<div className={classNames(styles.list)}>
					{filteredArticles.length > 0 ? (
						filteredArticles.slice().map(item => (
							<div key={item.id} className={classNames(styles.container, styles[detailLevelNames[this.props.detailLevel]])}>
								<ListItemType article={item} detailLevel={this.props.detailLevel} />
							</div>
						))
					) : (
						<div className={classNames(styles.noArticles)}>Keine Artikel gefunden.</div>
					)}
					{// UGLY HACK: create some empty filler elements to make the flex layout work in the last row
					// (preventing a smaller number of list items to stretch over the whole width)
					[null, null, null, null, null, null].map((val, index) => (
						<div
							key={index}
							className={classNames(styles.container, styles[detailLevelNames[this.props.detailLevel]], styles.containerFiller)}
						/>
					))}
				</div>
				{this.props.loadMore && this.state.searchTerm.length === 0 && (
					<mui.Button color="secondary" onClick={this.props.loadMore} style={{ display: 'block', margin: '0 auto 2em auto' }}>
						Mehr laden
					</mui.Button>
				)}
			</div>
		);
	}
}

export default withTheme(ArticleList);
