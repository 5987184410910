import { push } from 'connected-react-router';
import React from 'react';
import update from 'immutability-helper';
import DocumentMeta from 'react-document-meta';
import { connect } from 'react-redux';
import { Editor, ImageUpload, ImageUploadDialog, ImageUploadUtils, UploadTypeEnum } from '../../br3-components/components.webcms.ts';
import * as config from '../../config.webapp';
import {
	load as loadArticle,
	loadBoardList,
	loadRecommendations,
	save as saveArticle,
	update as updateArticle,
} from '../../redux/modules/article';
import { refreshArticle } from '../../redux/modules/dashboard';
import { identifyService } from '../../br3-components/commons/dataPrivacy';

/**
 * Connects the editor with the article's data.
 */
class ArticleEditorConnector extends React.Component {
	static fetchData(getState, dispatch, location, params) {
		let promises = [dispatch(loadBoardList()), dispatch(loadArticle({ ...params }))];

		if (params.id) {
			promises.push(loadRecommendations({ ...params, status: 'all' }, 4));
		}
		return Promise.all(promises);
	}

	state = {
		logs: [],
		trackedImageUploads: [],
	};

	uploadImages = article => {
		const stringifiedArticle = JSON.stringify(article);
		const utils = new ImageUploadUtils(stringifiedArticle);
		const imagesToBeUploaded = utils.getUniqueBlobUrls().map(
			url =>
				new ImageUpload(url, {
					uploadServer: `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.imageupload}`,
					downloadServer: config.PUBLIC_FACING_ORIGIN_FILESERVERDOWNLOAD,
					type: UploadTypeEnum.URL,
					identifier: url,
				})
		);

		let trackedImageUploads = [...imagesToBeUploaded];
		this.setState({ trackedImageUploads });

		return ImageUpload.uploadMultiple(imagesToBeUploaded, res => {
			trackedImageUploads = trackedImageUploads.filter(upload => upload.getIdentifier() !== res.identifier);
			this.setState({ trackedImageUploads });
		}).then(uploads => JSON.parse(utils.getProcessedData(uploads)));
	};

	/**
	 * Validates the article.
	 */
	validate = article => {
		// checks if all embeddings use a whitelisted service
		return article.attributes.content.elements
			.filter(elem => elem.element_type == 'embed')
			.map(elem => !!identifyService(elem.content.html))
			.reduce((prev, cur) => prev && cur, true);
	};

	onArticleChange = async changedArticle => {
		// validate article
		if (!this.validate(changedArticle)) {
			this.setState(
				update(this.state, {
					logs: {
						$push: [
							{
								code: 'Error',
								detail: 'Jedes Skript-Embedding muss einen erlaubten Service nutzen.',
								status: 400,
								title: 'Artikel nicht valide',
							},
						],
					},
				})
			);
			return;
		}

		// enforce facebook instant articles publication
		changedArticle = update(changedArticle, {
			attributes: { metadata: { facebook_ia_active: { $set: true } } },
		});
		// this.handleFacebookInstantArticle(changedArticle);

		const { article, saveArticle, updateArticle } = this.props;
		let request;

		changedArticle = await this.uploadImages(changedArticle);

		// check if article is NEW ==> history does not exist
		if (article.createdAt) {
			// article has a history ==> update
			delete changedArticle.relationships.recommended;
			request = updateArticle(changedArticle, article.id);
		} else {
			// article has no history ==> save via POST
			request = saveArticle(changedArticle);
		}

		this.setState({ pendingRequestsCount: this.state.pendingRequestsCount++ });

		request.then(
			successValue => {
				// send message
				this.setState(
					update(this.state, {
						logs: {
							$push: [
								{
									code: 'OK',
									detail: '',
									status: 200,
									title: 'Artikel gespeichert',
								},
							],
						},
					})
				);

				// redirect to article URL
				if (!article.id) {
					this.props.pushState(null, `/article/${successValue.result.data.id}?status=all`);
				}

				// refresh article in dashboard
				this.props.refreshArticle(successValue.result.data);
			},
			errorValue => {
				// show error dialog
				if (typeof errorValue === 'string') {
					this.setState(
						update(this.state, {
							logs: {
								$push: [JSON.parse(errorValue.error.response.text).errors[0]],
							},
						})
					);
				} else if (typeof errorValue === 'object') {
					this.setState(
						update(this.state, {
							logs: { $push: [errorValue.error.errors[0]] },
						})
					);
				}
			}
		);
	};

	render() {
		return (
			<div>
				<DocumentMeta title={'Bayern 3 CMS - Artikelansicht'} />
				<Editor
					initialData={{ ...this.props.article }}
					boards={this.props.boards}
					recommendations={this.props.recommendations}
					onChange={this.onArticleChange}
					logs={this.state.logs}
				/>
				<ImageUploadDialog trackedImageUploads={this.state.trackedImageUploads} />
			</div>
		);
	}
}

export default connect(
	state => ({
		article: state.article.data,
		recommendations: state.article.recommendations,
		boards: state.article.boards,
		error: state.article.error,
		loading: state.article.loading,
	}),
	{
		loadArticle,
		saveArticle,
		updateArticle,
		loadRecommendations,
		loadBoardList,
		refreshArticle,
		pushState: push,
	}
)(ArticleEditorConnector);
