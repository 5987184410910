/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './ContentCreator.scss';
import SvgFormatText from './format-text.svg';
import SvgPlayCircle from './play-circle.svg';
import SvgImageFilter from './image-filter.svg';
import SvgPuzzle from './puzzle.svg';

export default class ContentCreator extends React.Component {
	handleClick = element => {
		this.props.onAddElement(element);
	};

	render() {
		return (
			<div className={classNames(styles.contentcreator)}>
				<a onClick={this.handleClick.bind(this, 'text')}>
					<SvgFormatText className={classNames(styles.icon)} />
					Text
				</a>
				<a onClick={this.handleClick.bind(this, 'media')}>
					<SvgPlayCircle className={classNames(styles.icon)} />
					Audio/Video
				</a>
				<a onClick={this.handleClick.bind(this, 'gallery')}>
					<SvgImageFilter className={classNames(styles.icon)} />
					Foto/Galerie
				</a>
				<a onClick={this.handleClick.bind(this, 'embed')}>
					<SvgPuzzle className={classNames(styles.icon)} />
					Embed
				</a>
			</div>
		);
	}
}
