import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/de';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import routes from './routes';
import { AppBar } from '../br3-components/components.webcms.ts';
import AuthenticationProvider from '../br3-components/components/Login2FA/AuthenticationProvider';
import styles from './Cms.scss';
import 'medium-editor/dist/css/medium-editor.css';
import './medium-editor-styles.css';
import ErrorBoundary from '../br3-components/components/ErrorBoundary/ErrorBoundary';

moment.locale('de');

const meta = {
	title: 'Bayern 3 Newsroom',
	description: 'Bayern 3 Newsroom',
	meta: {
		charSet: 'utf-8',
	},
};

class Cms extends Component {
	static fetchData() {
		return Promise.all([]);
	}

	static propTypes = {
		location: PropTypes.object.isRequired,
		pushState: PropTypes.func,
	};

	state = {
		title: meta.title,
	};

	componentDidMount = () => {
		this.refreshTitle();
	};
	componentDidUpdate = () => {
		this.refreshTitle();
	};

	refreshTitle = () => {
		const { title } = DocumentMeta.peek() || meta;
		if (this.state.title !== title) {
			this.setState({
				title,
			});
		}
	};

	goHome = () => {
		const currentRoute = this.props.location;
		if (currentRoute && currentRoute.pathname !== '/') {
			this.props.pushState('/');
		}
	};

	render() {
		return (
			<ErrorBoundary>
				<AuthenticationProvider>
					{({ user, logout }) => {
						return (
							<div className={cx(styles.container)}>
								<ErrorBoundary>
									<AppBar onLeftIconButtonClick={this.goHome} user={user} title={this.state.title} logout={logout} />
								</ErrorBoundary>

								<div>
									<ErrorBoundary>{routes}</ErrorBoundary>
								</div>
							</div>
						);
					}}
				</AuthenticationProvider>
				<DocumentMeta {...meta} />
			</ErrorBoundary>
		);
	}
}

const ConnectedComponent = hot(
	withRouter(
		connect(
			state => ({
				location: state.router.location,
			}),
			{ pushState: push }
		)(withTheme(Cms))
	)
);
ConnectedComponent.fetchData = Cms.fetchData;

export default ConnectedComponent;
