import React from 'react';
import blue from '@material-ui/core/colors/blue';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export function buildMuiTheme() {
	return createMuiTheme({
		palette: {
			primary: blue,
			type: 'light',
		},
		typography: {
			fontSize: 20,
		},
	});
}

export const renderWrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<MuiThemeProvider theme={buildMuiTheme()}>
			<MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	);
};
