import { IUploadResult } from './ImageUpload';

const URL_QUERY_DELIMITER = '#';

// pattern to extract all blob urls
const BLOB_PATTERN = /(?:")(blob:.*?)(?:")/gi;

export interface IIndexResult {
	url: string;
	query: string;
}

/**
 * Utils for extracting image urls that need to be uploaded from articles and teaser boards.
 */
export default class ImageUploadUtils {
	stringifiedData: string;

	// MAPS
	blobUrlMap = new Map<string, string>(); // map for easy access to final URLs by using the blob URLs in our INDEXED_BLOBS array

	// ARRAYS
	indexedBlobs: IIndexResult[] = []; // will contain all our urls indexed by occurence in our source JSON; values will be objects containing 'url' and 'query'
	indexedUrls: string[] = []; // indexed array that will contain our final urls including the required query for each item

	/**
	 * Initialises the utils object.
	 * @param stringifiedData stringified json object
	 */
	constructor(stringifiedData: string) {
		this.stringifiedData = stringifiedData;
	}

	/**
	 * will split a blob url into url at index 0 and the query at index 1.
	 * @param url url to split
	 */
	private splitUrlAndQuery(url: string) {
		return url.split(URL_QUERY_DELIMITER);
	}

	/**
	 * Extracts all blob urls from the given document.
	 */
	getUniqueBlobUrls() {
		// extract blobs
		let loopedMatchArray;
		while ((loopedMatchArray = BLOB_PATTERN.exec(this.stringifiedData))) {
			const fullBlobUrl = loopedMatchArray[1];
			const parts = this.splitUrlAndQuery(fullBlobUrl);
			this.indexedBlobs.push({ url: parts[0], query: parts.length > 1 ? `?${parts[1]}` : '' });
		}
		return [...new Set(this.indexedBlobs.map(res => res.url))];
	}

	/**
	 * Replaces blobs with final urls and returns stringified JSON object
	 * @param urlsAndIdentifiers
	 */
	getProcessedData(urlsAndIdentifiers: IUploadResult[]) {
		urlsAndIdentifiers.forEach(upload => {
			const { identifier, url } = upload;
			this.blobUrlMap.set(identifier, url);
		});

		this.indexedUrls = this.indexedBlobs.map(item => {
			const { url, query } = item;
			return `${this.blobUrlMap.get(url)}${query}`;
		});

		let replacementIndex = 0;
		return this.stringifiedData.replace(BLOB_PATTERN, () => `"${this.indexedUrls[replacementIndex++]}"`);
	}

	flush() {
		this.blobUrlMap.clear();
		this.indexedBlobs = [];
		this.indexedUrls = [];
		this.stringifiedData = '';
	}
}
