import PropTypes from 'prop-types';
import React from 'react';
import styles from './ContentElementEmbed.scss';
import update from 'immutability-helper';

interface IProps {
	// @ts-ignore
	data: any;
	// @ts-ignore
	onChange: any;
}

/**
 * Editor for script embeddings.
 */
export default class EmbedEditorScript extends React.Component<IProps> {
	static propTypes = {
		data: PropTypes.object.isRequired,
	};

	// @ts-ignore
	onChange = (e: any) => {
		if (this.props.onChange) {
			this.props.onChange(
				update(this.props.data, {
					content: {
						html: {
							$set: e.target.value,
						},
					},
				})
			);
		}
	};

	render() {
		return (
			<div>
				<textarea rows={10} className={styles.scriptText} onChange={this.onChange} value={this.props.data.content.html} />
			</div>
		);
	}
}
