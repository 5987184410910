import React, { Component } from 'react';
import {
	BannerManager,
	NavigationManager,
	ArticleManager,
	ImageUpload,
	ImageUploadUtils,
	UploadTypeEnum,
} from '../../br3-components/components.webcms.ts';
import { connect } from 'react-redux';
import { searchArticle, loadNavigation, saveNavigation, loadArticles, loadBanners, saveBanner } from '../../redux/modules/dashboard';
import styles from './DashboardConnector.scss';
import classNames from 'classnames';
import DocumentMeta from 'react-document-meta';
import { push } from 'connected-react-router';
import * as config from '../../config.webapp';

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
/**
 * Home view of the cms for selecting articles, rearranging the navigation and managing banners.
 */
class DashboardConnector extends Component {
	static fetchData(getState, dispatch, location, params) {
		var promises = [];

		if (!getState().dashboard.articlesLoaded) {
			promises.push(dispatch(loadArticles({ ...params })));
		}
		if (!getState().dashboard.bannersLoaded) {
			promises.push(dispatch(loadBanners({ ...params })));
		}
		if (!getState().dashboard.navigationLoaded) {
			promises.push(dispatch(loadNavigation({ ...params })));
		}
		// if (!getState().dashboard.piwikLoaded) {
		// 	promises.push(dispatch(loadPiwik()));
		// }
		return Promise.all(promises);
	}

	onBannerSave = async changedBanner => {
		changedBanner = await this.uploadBanner(changedBanner);
		await this.props.saveBanner(changedBanner).promise;
		await delay(1000); // delay reload by 1s to ensure data is refreshed in database
		await this.props.loadBanners().promise;
	};

	uploadBanner = banner => {
		const stringifiedBanner = JSON.stringify(banner);
		const utils = new ImageUploadUtils(stringifiedBanner);
		const imagesToBeUploaded = utils.getUniqueBlobUrls().map(
			url =>
				new ImageUpload(url, {
					uploadServer: `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.imageupload}`,
					downloadServer: config.PUBLIC_FACING_ORIGIN_FILESERVERDOWNLOAD,
					type: UploadTypeEnum.URL,
					identifier: url,
				})
		);

		return ImageUpload.uploadMultiple(imagesToBeUploaded).then(uploads => JSON.parse(utils.getProcessedData(uploads)));
	};

	loadMoreArticles = () => {
		this.props.loadArticles({}, this.props.articlesPagination);
	};

	onSaveNavigation = newNavigation => {
		this.props.saveNavigation(newNavigation, this.props.mainNavigation);
	};

	render() {
		return (
			<div className={classNames(styles.container)}>
				<DocumentMeta title={'Bayern 3 CMS - Dashboard'} />
				<div className={classNames(styles.leftCol)}>
					{this.props.articles && (
						<ArticleManager
							pushState={this.props.pushState}
							initialData={this.props.articles}
							loading={this.props.articlesLoading}
							errors={this.props.articlesErrors}
							// piwikData={this.props.piwik}
							searchArticle={this.props.searchArticle}
							searchResults={this.props.searchResults}
							loadMore={this.loadMoreArticles}
						/>
					)}
				</div>
				<div className={classNames(styles.rightCol)}>
					<NavigationManager initialData={this.props.mainNavigation} onSave={this.onSaveNavigation} />
					{this.props.banners && <BannerManager banners={this.props.banners} onSave={this.onBannerSave} />}
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		mainNavigation: state.dashboard.mainNavigation,
		articles: state.dashboard.articles,
		articlesPagination: state.dashboard.articlesPagination,
		banners: state.dashboard.banners,
		articlesLoaded: state.dashboard.articlesLoaded,
		articlesLoading: state.dashboard.articlesLoading,
		articlesErrors: state.dashboard.articlesErrors,
		bannersLoaded: state.dashboard.bannersLoaded,
		navigationLoaded: state.dashboard.navigationLoaded,
		searchResults: state.dashboard.searchResults,
	}),
	{ searchArticle, loadNavigation, saveNavigation, loadArticles, loadBanners, saveBanner, pushState: push }
)(DashboardConnector);
