/*eslint no-console:0*/

import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import update from 'immutability-helper';
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

class NavigationEditor extends React.Component {
	static defaultProps = {
		data: {
			link: '',
			title: '',
			visible: true,
		},
	};

	state = {
		data: this.props.data,
		showEditDialog: false,
	};

	componentDidMount = () => {
		this.setState({ showEditDialog: true });
	};

	componentWillUnmount = () => {
		this.setState({ showEditDialog: false });
	};

	onSave = () => {
		if (this.state.data.title.length > 0 && this.state.data.link.length > 0) {
			this.props.onSave(this.state.data);
		}
	};

	onCancel = () => {
		this.setState({ showEditDialog: false });
		// wait for dialog to disapper
		setTimeout(() => {
			this.props.onCancel();
		}, 500);
	};

	onChange = (key, e) => {
		this.setState(
			update(this.state, {
				data: { [key]: { $set: e.target.value } },
			})
		);
	};

	render() {
		return (
			<mui.Dialog open={this.state.showEditDialog} onClose={() => this.setState({ showEditDialog: false })} fullWidth={true} maxWidth="sm">
				<mui.DialogTitle>{this.props.title}</mui.DialogTitle>
				<mui.DialogContent>
					<mui.TextField label="Name" value={this.state.data.title} onChange={this.onChange.bind(this, 'title')} fullWidth={true} />
					<mui.TextField
						label="Link"
						placeholder="Für externen Link mit https://... beginnen."
						value={this.state.data.link}
						onChange={this.onChange.bind(this, 'link')}
						fullWidth={true}
						margin="normal"
					/>
					{/^\/r\/[A-Za-z0-9_-]+/.test(this.state.data.link) ? (
						<div>
							Dieser Navigationspunkt verlinkt auf ein Teaserboard. Existiert dieses Teaserboard noch nicht, wird es automatisch erstellt.
						</div>
					) : null}
					{this.props.i > -1 && (
						<mui.Button color="secondary" style={{ float: 'right' }} onClick={this.props.onDelete.bind(this, this.props.i)}>
							Löschen
						</mui.Button>
					)}
				</mui.DialogContent>
				<mui.DialogActions>
					<mui.Button onClick={this.onCancel}>Abbrechen</mui.Button>
					<mui.Button color="primary" onClick={this.onSave}>
						Speichern
					</mui.Button>
				</mui.DialogActions>
			</mui.Dialog>
		);
	}
}

export default DragDropContext(HTML5Backend)(withTheme(NavigationEditor));
