import React from 'react';
import { Route, Switch } from 'react-router';
import DashboardConnector from './views/DashboardConnector';
import ArticleEditorConnector from './views/ArticleEditorConnector';
import TeaserBoardConnector from './views/TeaserBoardConnector';
import { withPrefetch } from '../helpers/utils';

export default (
	<Switch>
		<Route exact path="/" component={withPrefetch(DashboardConnector)} />
		<Route path="/article/:id" component={withPrefetch(ArticleEditorConnector)} />
		<Route path="/article" component={withPrefetch(ArticleEditorConnector)} />
		<Route path="/board/:id" component={withPrefetch(TeaserBoardConnector)} />
		<Route path="/:id" component={withPrefetch(ArticleEditorConnector)} />
	</Switch>
);
