import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

if (typeof window !== 'undefined') {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	var MediumEditor = require('medium-editor');
}

export default class ReactMediumEditor extends React.Component {
	static defaultProps = {
		tag: 'div',
	};
	static defaultOptions = {
		anchor: {
			placeholderText: 'Linkadresse einfügen',
			targetCheckboxText: 'in neuem Tab öffnen',
		},
	};
	static propTypes = {
		options: PropTypes.object,
		text: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		tag: PropTypes.string.isRequired,
		contentEditable: PropTypes.bool,
		dangerouslySetInnerHTML: PropTypes.object,
	};

	buildOptions = () => {
		const options = this.props.options;
		options.anchor = options.anchor
			? { ...ReactMediumEditor.defaultOptions.anchor, ...options.anchor }
			: ReactMediumEditor.defaultOptions.anchor;
		return options;
	};

	componentDidMount() {
		// eslint-disable-next-line react/no-find-dom-node
		const dom = ReactDOM.findDOMNode(this);

		this.text = this.props.text;
		this.medium = new MediumEditor(dom, this.buildOptions());
		this.medium.subscribe('editableInput', () => {
			this.change(dom.innerHTML);
		});
	}

	componentDidUpdate() {
		if (this.text !== this.props.text) {
			this.text = this.props.text;
			this.medium.setContent(this.text);
		}
		this.medium.restoreSelection();
	}

	componentWillUnmount() {
		this.medium.destroy();
	}

	render() {
		const { text, tag, ...props } = this.props;
		props.dangerouslySetInnerHTML = { __html: text };

		if (this.medium) {
			this.medium.saveSelection();
		}

		return React.createElement(tag, props);
	}

	change(text) {
		this.text = text;
		if (this.props.onChange) this.props.onChange(text, this.medium);
	}
}
