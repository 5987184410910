import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	isLoaded as isTeaserBoardLoaded,
	rearrange as rearrangeTeaserBoard,
	load as loadTeaserBoard,
	loadMoreArticles,
	update as updateTeaserBoard,
} from '../../redux/modules/teaserBoard';
import { searchArticle } from '../../redux/modules/dashboard';
import { bindActionCreators } from 'redux';
import { TeaserBoardEditor } from '../../br3-components/components.webcms.ts';
import { nTeasersForLayoutType } from '../../br3-components/components/TeaserBoard/TeaserBoard.js';
import DocumentMeta from 'react-document-meta';

const teaserBoardLayoutNames = ['A', 'B', 'C'];
const teaserBoardLayoutIndices = { A: 0, B: 1, C: 2 };

/**
 * View for editing the teaser boards.
 */
class TeaserBoardConnector extends Component {
	static displayName = 'TeaserBoardConnector';

	static fetchData(getState, dispatch, location, params) {
		if (!isTeaserBoardLoaded(getState()) || getState().teaserBoard.id !== params.id) {
			return dispatch(loadTeaserBoard({ ...params }));
		}
	}

	handleTeasersChange = teasers => {
		this.props.rearrangeTeaserBoard(
			this.props.teaserBoard.attributes.metadata.board_type,
			teasers.slice(0, nTeasersForLayoutType[this.props.layoutType])
		);
	};

	handleLayoutTypeChange = layoutType => {
		if (teaserBoardLayoutIndices[layoutType] !== this.props.teaserBoard.attributes.metadata.board_type)
			this.props.rearrangeTeaserBoard(
				teaserBoardLayoutIndices[layoutType],
				this.props.teaserBoard.relationships.pinned_articles.data.slice(0, nTeasersForLayoutType[layoutType])
			);
	};

	handleSave = () => {
		let patch = {
			data: {
				type: 'board',
				id: this.props.teaserBoard.id,
				attributes: {
					metadata: {
						board_type: this.props.teaserBoard.attributes.metadata.board_type,
					},
				},
				relationships: {
					pinned_articles: {
						data: this.props.pinnedArticles.map(article => article && { type: 'article', id: article.id }).filter(a => a),
					},
				},
			},
		};
		this.props.updateTeaserBoard(patch, this.props.teaserBoard.id);
	};

	loadMoreArticles = () => this.props.loadMoreArticles(this.props.matchingArticlesPagination);

	render() {
		return (
			<TeaserBoardEditor
				layoutType={teaserBoardLayoutNames[this.props.teaserBoard.attributes.metadata.board_type]}
				articles={this.props.matchingArticles}
				teasers={this.props.pinnedArticles}
				loadMore={this.props.matchingArticlesPagination ? this.loadMoreArticles : null}
				loading={this.props.loading || this.props.saving}
				success={this.props.saved && !this.props.saving ? 'Layout veröffentlicht.' : null}
				errors={this.props.errors}
				onTeasersChange={this.handleTeasersChange}
				onLayoutTypeChange={this.handleLayoutTypeChange}
				onSave={this.handleSave}
				changed={!this.props.saved}
				searchArticle={this.props.searchArticle}
				searchResults={this.props.searchResults}
			>
				<DocumentMeta title={'Bayern 3 CMS - TeaserBoard-Ansicht'} />
			</TeaserBoardEditor>
		);
	}
}

export default connect(
	globalState => ({
		teaserBoard: globalState.teaserBoard.data,
		pinnedArticles: globalState.teaserBoard.data.relationships.pinned_articles.data,
		matchingArticles: globalState.teaserBoard.data.relationships.matching_articles.data,
		matchingArticlesPagination: globalState.teaserBoard.matchingArticlesPagination,
		errors: globalState.teaserBoard.errors,
		loading: globalState.teaserBoard.loading,
		saving: globalState.teaserBoard.saving,
		saved: globalState.teaserBoard.saved,
		searchResults: globalState.dashboard.searchResults,
	}),
	dispatch => bindActionCreators({ searchArticle, rearrangeTeaserBoard, loadTeaserBoard, loadMoreArticles, updateTeaserBoard }, dispatch)
)(TeaserBoardConnector);
