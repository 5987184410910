/*eslint no-console:0*/

// TODOs :
// - [x] restore save procedure (hide dialog only when save finished, then use fetched reloaded banners)
// - [x] restore delete procedure similar to save procedure
// - [x] make sure we have the id bug fixed (global TODOs)
// - [ ] turn class into SFC and use react hooks
// - [ ] use immutable data
// - [ ] use local redux state update on save / update response received instead of re-querying data via timeout (search `timeout`)

import * as mui from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { themeableForCms } from '../../commons/utils/cms';
import wording from '../../commons/wording';
import articleStyles from '../ArticleList/ArticleList.scss';
// @ts-ignore
import DashboardContainer from '../DashboardContainer/DashboardContainer';
import BannerEditor from './BannerEditor';
import styles from './BannerManager.scss';
import SvgImageBroken from './image-broken.svg';
import { IBanner } from '../../../types/Banner';

function sortBanners(bannerA: IBanner, bannerB: IBanner) {
	const [primaryOrderAttribute, secondaryOrderAttribute] = ['createdAt', 'updatedAt'];

	const dateA = bannerA[primaryOrderAttribute] || bannerA[secondaryOrderAttribute];
	const dateB = bannerB[primaryOrderAttribute] || bannerB[secondaryOrderAttribute];

	if (dateA > dateB) return -1;
	if (dateA < dateB) return 1;
	return 0;
}

interface IProps {
	banners: IBanner[];
	onSave: (banner: IBanner) => Promise<void>;
}

enum OpenDialogTypes {
	'none',
	'edit',
	'new',
}

function BannerManager(props: IProps = { banners: [], onSave: () => Promise.resolve() }) {
	const [dialogState, setDialogState] = useState(OpenDialogTypes.none);
	const [currentBanner, setCurrentBanner] = useState();

	async function handleDeleteBanner(banner: IBanner) {
		if (confirm('Soll der Banner wirklich gelöscht werden?')) {
			const bannerCopy: IBanner = JSON.parse(JSON.stringify(banner)); // TODO: use typed-immutable-record or immutablejs to handle prop changes

			bannerCopy.attributes.metadata.doc.status = 'depublished';
			await props.onSave(bannerCopy);
			setDialogState(OpenDialogTypes.none);
		}
	}

	async function handleSaveBanner(banner: IBanner) {
		await props.onSave(banner);
		setDialogState(OpenDialogTypes.none);
	}

	const bannersByLatestUpdateOrCreate = Array.from(props.banners).sort(sortBanners);
	const publishedBanners = bannersByLatestUpdateOrCreate.filter(banner => banner.attributes.metadata.doc.status !== 'depublished');

	return (
		<DashboardContainer
			className={styles.bannerManager}
			title="Banner"
			onAdd={() => {
				setCurrentBanner(undefined);
				setDialogState(OpenDialogTypes.new);
			}}
		>
			{dialogState !== OpenDialogTypes.none && (
				<BannerEditor
					onSave={handleSaveBanner}
					onDelete={handleDeleteBanner}
					onCancel={() => setDialogState(OpenDialogTypes.none)}
					data={currentBanner}
					title={dialogState === OpenDialogTypes.edit ? 'Banner bearbeiten' : 'Banner erstellen'}
				/>
			)}

			<div className={classNames(styles.banners)}>
				{publishedBanners.length === 0 && <div className={classNames(styles.nobanners)}>Keine Banner angelegt.</div>}
				{publishedBanners.map((banner: IBanner) => (
					<BannerItem
						key={banner.id}
						banner={banner}
						onEdit={() => {
							setCurrentBanner(banner);
							setDialogState(OpenDialogTypes.edit);
						}}
					/>
				))}
			</div>
		</DashboardContainer>
	);
}

const BannerItem = ({ banner, onEdit }: { banner: IBanner; onEdit: (banner: IBanner) => void }) => (
	<mui.Paper className={classNames(styles.banner)} onClick={() => onEdit(banner)}>
		<div className={classNames(styles.image)} style={{ backgroundImage: `url(${banner.attributes.content.image_source})` }}>
			{banner.attributes.content.image_source === '' && <SvgImageBroken className={classNames(styles.noimage)} />}
		</div>

		<div className={classNames(articleStyles.publishingInfo)}>
			<div className={classNames(articleStyles.status, articleStyles[banner.attributes.metadata.doc.status], styles.status)}>
				{wording.banner[banner.attributes.metadata.doc.status]}
			</div>
		</div>
		<h4 className={styles.title}>{banner.attributes.content.title}</h4>
		<em />
	</mui.Paper>
);

export default themeableForCms(hot(BannerManager));
