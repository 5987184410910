/*eslint no-console:0*/

import PropTypes from 'prop-types';
import React from 'react';
import { ArticleList } from '../../components.webcms';
import DashboardContainer from '../DashboardContainer/DashboardContainer';

export default class ArticleManager extends React.Component {
	static propTypes = {
		pushState: PropTypes.func,
		searchArticle: PropTypes.func,
	};

	static defaultProps = {
		pushState: () => {},
	};

	render() {
		const articles = this.props.initialData;

		// if (articles && this.props.piwikData) {
		// 	articles.forEach(a => {
		// 		a.piwik = this.props.piwikData[a.id];
		// 	});
		// }
		return (
			<DashboardContainer title="Artikel" onAdd={() => this.props.pushState('/article')}>
				<ArticleList
					articles={articles || []}
					showStatusFilter
					detailLevel={2}
					errors={this.props.errors}
					itemsDraggable={false}
					itemsClickable={true}
					loadMore={this.props.loadMore}
					searchArticle={this.props.searchArticle}
					searchResults={this.props.searchResults}
				/>
			</DashboardContainer>
		);
	}
}
