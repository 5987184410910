import React from 'react';
import ElementReorderItem from './ElementReorderItem';
import { withTheme } from '@material-ui/core/styles';

class ElementReorder extends React.Component {
	render() {
		return (
			<div>
				{this.props.elements.map((e, i) => (
					<ElementReorderItem id={e.id} key={e.id} element={e} index={i} moveElement={this.props.moveElement} />
				))}
			</div>
		);
	}
}

export default withTheme(ElementReorder);
