// import * as clientConfig from './config.webapp';
export * from './config.webapp';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const accessor = typeof window !== 'undefined' ? (window as Window & { env: any }) : (process as { env: any });

// export default {
// ...clientConfig.default,
export const AUTH_GROUPS = accessor.env.AUTH_GROUPS!;
export const AUTH_APPLICATION = accessor.env.AUTH_APPLICATION!;
export const PUBLIC_FACING_ORIGIN_BRAUTH = accessor.env.PUBLIC_FACING_ORIGIN_BRAUTH;

export const FIREBASE = {
	url: accessor.env.FIREBASE_URL!,
	key: accessor.env.FIREBASE_KEY!,
	topic: accessor.env.FIREBASE_TOPIC!,
	sound: accessor.env.FIREBASE_SOUND!,
};
// };
