import PropTypes from 'prop-types';
import React from 'react';
import CropperJs from 'cropperjs';
import 'cropperjs/dist/cropper.css';

class Cropper extends React.Component {
	static propTypes = {
		// react cropper options
		crossOrigin: PropTypes.string,
		src: PropTypes.string,
		alt: PropTypes.string,
		style: PropTypes.object,

		// cropper options
		aspectRatio: PropTypes.number,
		crop: PropTypes.func,
		preview: PropTypes.string,
		strict: PropTypes.bool,
		responsive: PropTypes.bool,
		checkImageOrigin: PropTypes.bool,
		background: PropTypes.bool,
		modal: PropTypes.bool,
		guides: PropTypes.bool,
		highlight: PropTypes.bool,
		autoCrop: PropTypes.bool,
		autoCropArea: PropTypes.number,
		dragCrop: PropTypes.bool,
		movable: PropTypes.bool,
		cropBoxMovable: PropTypes.bool,
		cropBoxResizable: PropTypes.bool,
		doubleClickToggle: PropTypes.bool,
		zoomable: PropTypes.bool,
		mouseWheelZoom: PropTypes.bool,
		touchDragZoom: PropTypes.bool,
		rotatable: PropTypes.bool,
		minContainerWidth: PropTypes.number,
		minContainerHeight: PropTypes.number,
		minCanvasWidth: PropTypes.number,
		minCanvasHeight: PropTypes.number,
		minCropBoxWidth: PropTypes.number,
		minCropBoxHeight: PropTypes.number,
		build: PropTypes.func,
		built: PropTypes.func,
		dragstart: PropTypes.func,
		dragmove: PropTypes.func,
		dragend: PropTypes.func,
		zoomin: PropTypes.func,
		zoomout: PropTypes.func,
	};

	static defaultProps = {
		src: null,
	};

	imgRef = React.createRef();

	componentDidMount() {
		var options = {};
		for (var prop in this.props) {
			if (prop !== 'src' && prop !== 'alt' && prop !== 'crossOrigin') {
				options[prop] = this.props[prop];
			}
		}

		this.cropper = new CropperJs(this.imgRef.current, options);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.src !== this.props.src) {
			this.replace(this.props.src);
		}
		if (prevProps.aspectRatio !== this.props.aspectRatio) {
			this.setAspectRatio(this.props.aspectRatio);
		}
	}

	componentWillUnmount() {
		if (this.cropper) {
			// Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
			this.cropper.destroy();
			// While we're at it remove our reference
			delete this.cropper;
		}
	}

	move = (offsetX, offsetY) => this.cropper.move(offsetX, offsetY);
	zoom = ratio => this.cropper.zoom(ratio);
	rotate = degree => this.cropper.rotate(degree);
	enable = () => this.cropper.enable();
	disable = () => this.cropper.disable();
	reset = () => this.cropper.reset();
	clear = () => this.cropper.clear();
	replace = url => this.cropper.replace(url);
	getData = () => this.cropper.getData();
	getContainerData = () => this.cropper.getContainerData();
	getImageData = () => this.cropper.getImageData();
	getCanvasData = () => this.cropper.getCanvasData();
	setCanvasData = data => this.cropper.setCanvasData(data);
	getCropBoxData = () => this.cropper.getCropBoxData();
	setCropBoxData = data => this.cropper.setCropBoxData(data);
	setData = data => this.cropper.setData(data);
	getCroppedCanvas = options => this.cropper.getCroppedCanvas(options);
	setAspectRatio = aspectRatio => this.cropper.setAspectRatio(aspectRatio);
	setDragMode = () => this.cropper.setDragMode();
	on = (eventname, callback) => this.imgRef.current.addEventListener(eventname, callback);

	render() {
		const { src, alt, crossOrigin, style } = this.props;

		// HINT: `rest` was also expanded onto our div - however it seems it has no effect. In case we encounter problems, let's restore this
		// behavior with react 16, which allows any attribute on react dom elements

		return (
			<div style={style}>
				<img crossOrigin={crossOrigin} ref={this.imgRef} src={src} alt={alt === undefined ? 'picture' : alt} style={{ opacity: 0 }} />
			</div>
		);
	}
}

export default Cropper;
