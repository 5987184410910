import fetch from 'cross-fetch';
import { authClient } from '../Login2FA/authClient';

/**
 * Types for upload data.
 */
export enum UploadTypeEnum {
	BLOB,
	DATA_URL,
	URL,
}

/**
 * Options for ImageUpload.
 */
export interface IUploadOptions {
	type?: UploadTypeEnum;
	downloadServer: string;
	uploadServer: string;
	identifier: string;
}

/**
 * Result of a successful ImageUpload.
 */
export interface IUploadResult {
	url: string;
	identifier: string;
}

const BASE64_MARKER = ';base64,';

/**
 * Handler to upload images to the image server.
 */
export default class ImageUpload {
	private data: Blob | string;
	private options: IUploadOptions;

	/**
	 * Initializes the upload handler.
	 * @param data Image to upload as blob or url string
	 * @param options Upload options
	 */
	constructor(data: Blob | string, options: IUploadOptions) {
		this.data = data;
		this.options = options;

		if (!this.options.type) {
			if (this.data instanceof Blob) {
				// it's a blob
				this.options.type = UploadTypeEnum.BLOB;
			} else if (typeof this.data === 'string') {
				if (this.data.indexOf('data:') === 0) {
					// it's a dataURL
					this.options.type = UploadTypeEnum.DATA_URL;
				} else if (this.data.search(/(blob:|https?:)/) >= 0) {
					// it's either a local blob url or a remote url
					this.options.type = UploadTypeEnum.URL;
				}
			}
		}

		if (!this.options.type) {
			throw new Error('Could not determine type of data.');
		}
	}

	/**
	 * Uploads multiple images parallely.
	 * @param imageUploads upload handler
	 * @param callback gets called everytime an image has been uploaded successfully
	 */
	static uploadMultiple(imageUploads: ImageUpload[], callback?: (res: IUploadResult) => void): Promise<IUploadResult[]> {
		const promises = imageUploads.map(imgUpload =>
			imgUpload.upload().then(res => {
				if (callback) {
					callback(res);
				}
				return res;
			})
		);
		return Promise.all(promises);
	}

	/**
	 * Uploads the contained image.
	 */
	async upload(): Promise<IUploadResult> {
		const blobData = await this.getBlobData();
		let query = '';
		if (this.options.type !== UploadTypeEnum.BLOB) {
			query = this.extractQueryParamsFromUrl(this.data as string);
		}

		const formData = new FormData();
		formData.append('file', blobData);
		formData.append('public', 'true');

		return fetch(this.options.uploadServer, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authClient.getUser().credentials.accessToken}`,
			},
			credentials: 'include',
			body: formData,
		})
			.then(res => {
				if (res.ok) {
					return res.json();
				}
				throw { response: res, status: res.status, identifier: this.options.identifier };
			})
			.then(json => ({ url: `${json.url}${query}`, identifier: this.options.identifier }));
	}

	getIdentifier(): string {
		return this.options.identifier;
	}
	getData(): Blob | string {
		return this.data;
	}

	/**
	 * Extracts the blob data from the data field.
	 */
	private getBlobData(): Promise<Blob> {
		switch (this.options.type) {
			case UploadTypeEnum.BLOB:
				return Promise.resolve(this.data as Blob);
			case UploadTypeEnum.DATA_URL:
				return Promise.resolve(this.extractBlobFromDataUrl(this.data as string));
			case UploadTypeEnum.URL:
				return this.extractBlobFromUrl(this.data as string);
		}
		throw new Error('Could not get the blob data due to missing data type');
	}

	/**
	 * Extracts the blob data from a data url.
	 * @param dataURL url to the image
	 */
	private extractBlobFromDataUrl(dataURL: string): Blob {
		let parts, contentType, raw;

		if (dataURL.indexOf(BASE64_MARKER) == -1) {
			parts = dataURL.split(',');
			contentType = parts[0].split(':')[1];
			raw = decodeURIComponent(parts[1]);
			return new Blob([raw], { type: contentType });
		}
		parts = dataURL.split(BASE64_MARKER);
		contentType = parts[0].split(':')[1];
		raw = window.atob(parts[1]);
		let rawLength = raw.length;
		let uInt8Array = new Uint8Array(rawLength);
		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = raw.charCodeAt(i);
		}
		return new Blob([uInt8Array], { type: contentType });
	}

	/**
	 * Gets the blob data from an url.
	 * @param url url as string
	 */
	private extractBlobFromUrl(url: string): Promise<Blob> {
		return fetch(url).then(res => {
			if (res.ok) {
				return res.blob();
			}
			throw new Error(`Could not access requested url (${url})`);
		});
	}

	/**
	 * Extracts the parameters from an url.
	 * @param urlWithParams url with parameters
	 */
	private extractQueryParamsFromUrl(urlWithParams: string): string {
		// TODO refactor and use URL api
		let delimiter: string;
		if (urlWithParams.search(/https?:/) === 0) {
			// dataURL
			delimiter = '?';
		} else if (urlWithParams.search(/blob:/) === 0) {
			// blobURL
			delimiter = '#';
		} else if (urlWithParams.search(/data:/) === 0) {
			// not yet implemented
		}

		const parts = urlWithParams.split(delimiter!);
		return parts[1] ? `?${parts[1]}` : '';
	}
}
