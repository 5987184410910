import React from 'react';
import ReactDOM from 'react-dom';
import { DropTarget, DragSource } from 'react-dnd';
import styles from './ElementReorder.scss';
import { compose } from '../../../helpers/utils';

const source = {
	beginDrag(props) {
		return {
			id: props.id,
			index: props.index,
		};
	},
};

const target = {
	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		if (dragIndex === hoverIndex) return;

		// Determine rectangle on screen

		// eslint-disable-next-line react/no-find-dom-node
		const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();
		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
		// Determine mouse position
		const clientOffset = monitor.getClientOffset();
		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

		props.moveElement(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	},
};

class ElementReorderItem extends React.Component {
	render() {
		const { isDragging, connectDragSource, connectDropTarget } = this.props;

		return connectDragSource(
			connectDropTarget(
				<div id={this.props.element.id} style={{ opacity: isDragging ? 0 : 1 }} className={styles.reorderElement}>
					{this.props.element.element_type} : {this.props.element.element_subtype}
				</div>
			)
		);
	}
}

export default compose(
	DropTarget('item', target, connect => ({
		connectDropTarget: connect.dropTarget(),
	})),
	DragSource('item', source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}))
)(ElementReorderItem);
