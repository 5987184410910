import React from 'react';
import * as mui from '@material-ui/core';
import update from 'immutability-helper';

interface IProps {
	// @ts-ignore
	data: any;
	// @ts-ignore
	onChange: any;
}

/**
 * Editor for embedded facebook videos.
 */
export default class EmbedEditorFacebookVideo extends React.Component<IProps> {
	// @ts-ignore
	onChange = (e: any) => {
		if (this.props.onChange) {
			this.props.onChange(
				update(this.props.data, {
					content: {
						html: {
							$set: this.props.data.content.html.replace(/ data-href="([^"]*)"/, ' data-href="' + e.target.value + '"'),
						},
					},
				})
			);
		}
	};

	getVideoURL = (): string => {
		const a = this.props.data.content.html.match(/ data-href="([^"]*)"/);
		if (a.length > 1) {
			return a[1];
		} else {
			return '';
		}
	};

	render() {
		return (
			<div>
				<mui.TextField
					fullWidth={true}
					value={this.getVideoURL()}
					placeholder="https://www.facebook.com/facebook/videos/10153231379946729/"
					onChange={this.onChange}
				/>
			</div>
		);
	}
}
