import React from 'react';
import ArticleListItem from './ArticleListItem.js';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

const articleListItemSource = {
	beginDrag(props) {
		return props.article;
	},
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	};
}

class DraggableArticleListItem extends React.Component {
	componentDidMount() {
		this.props.connectDragPreview(getEmptyImage(), {
			captureDraggingState: true,
		});
	}

	render() {
		const { connectDragSource, style, article } = this.props;
		return connectDragSource(
			<div>
				<ArticleListItem {...{ style, article }} />
			</div>
		);
	}
}

export default DragSource('article', articleListItemSource, collect)(DraggableArticleListItem);
