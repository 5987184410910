import PropTypes from 'prop-types';
import React from 'react';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styles from './ImageUploadDialog.scss';
import { ImageUpload } from '../../components.webcms';

interface IProps {
	trackedImageUploads: ImageUpload[];
}

interface IState {
	isDialogOpen: boolean;
}

/**
 * Shows a dialog during image upload.
 */
class ImageUploadDialog extends React.Component<IProps, IState> {
	static propTypes = {
		trackedImageUploads: PropTypes.array,
	};

	static defaultProps = {
		trackedImageUploads: [],
	};

	state = {
		isDialogOpen: false,
	};

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.trackedImageUploads.length !== this.props.trackedImageUploads.length) {
			this.setState({ isDialogOpen: this.props.trackedImageUploads.length > 0 });
		}
	}

	render() {
		const uploads = this.props.trackedImageUploads
			.reverse()
			.map((upload, index) => (
				<li key={`image-${index}`} className={styles.imageItem} style={{ backgroundImage: `url(${upload.getData()})` }} />
			));

		return (
			<mui.Dialog open={this.state.isDialogOpen}>
				<mui.DialogTitle>Upload von Bildern wird durchgeführt</mui.DialogTitle>
				<mui.DialogContent>
					<div className={styles.imageListContainer}>
						<ul className={styles.imageList}>{uploads}</ul>
					</div>
				</mui.DialogContent>
			</mui.Dialog>
		);
	}
}

// @ts-ignore
export default withTheme(ImageUploadDialog);
