import Snackbar from '@material-ui/core/Snackbar';
import { User } from '@sep/br-auth-client';
import React from 'react';
import { authClient } from './authClient';
import { LoginContainer, LogoutContainer, TwoFactorContainer, WaitForTokenContainer } from './Login2FA.parts';
import { withStyles, CssBaseline, Paper, Typography, Theme, createStyles } from '@material-ui/core';
import Bayern3Svg from './bayern3-new.svg';

interface IState {
	username?: string;
	password?: string;
	isTwoFactorInProgress: boolean;
	isLoginDisabled: boolean;
	otp: string;
	hasError: boolean;
	currentErrorMessage?: string;
}

interface IProps {
	onLoginSuccess: () => void;
	user: User;
	logout: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	classes: any;
}

const styles = (theme: Theme) =>
	createStyles({
		main: {
			position: 'absolute',
			top: 0,
			height: '100%',
			width: '100%',
			backgroundImage: `url(${require('./altmuehltal-bg.jpg')})`,
			backgroundSize: 'cover',
		},
		container: {
			width: 'auto',
			display: 'block', // Fix IE 11 issue.
			marginLeft: theme.spacing(3),
			marginRight: theme.spacing(3),
			[theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
				width: 400,
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		paper: {
			marginTop: theme.spacing(8),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
		},
		logo: {
			maxWidth: '100%',
			maxHeight: '100px',
		},
		shake: {
			animation: `$shake .7s`,
		},
		'@keyframes shake': {
			'10%, 90%': {
				transform: 'translate3d(-1px, 0, 0)',
			},
			'20%, 80%': {
				transform: 'translate3d(2px, 0, 0)',
			},
			'30%, 50%, 70%': {
				transform: 'translate3d(-4px, 0, 0)',
			},
			'40%, 60%': {
				transform: 'translate3d(4px, 0, 0)',
			},
		},
	});

class Login2FA extends React.Component<IProps, IState> {
	state = {
		currentErrorMessage: '',
		hasError: false,
		isLoginDisabled: false,
		isTwoFactorInProgress: typeof this.props.user.getAuthToken() === 'string',
		otp: '',
		password: '',
		username: '',
	};

	formContainerRef = React.createRef<HTMLDivElement>();

	onError = (err: Error) => {
		const nextState = { ...this.state };

		nextState.hasError = true;

		if (err.message.includes('You have tried too many times')) {
			nextState.currentErrorMessage = 'Zu viele Versuche. Bitte warte einige Sekunden.';
		}

		if (err.message.includes('username or password invalid')) {
			nextState.currentErrorMessage = 'Ungültiger Benutzername oder Passwort.';
		}

		if (err.message.includes('Not authorized')) {
			nextState.currentErrorMessage = 'Keine Rechte für dieses System oder Code nicht mehr gültig.';
		}

		this.setState(nextState);
	};

	onLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.currentTarget;

		const nextState = { ...this.state, hasError: false };

		switch (id) {
			case 'username':
				nextState.username = value;
				break;
			case 'password':
				nextState.password = value;
		}

		this.setState(nextState);
	};

	onLoginSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		const { username, password } = this.state;

		try {
			this.setState({ isLoginDisabled: true, hasError: false });
			await authClient.authorize(username, password);
			this.setState({ isTwoFactorInProgress: true, isLoginDisabled: false });
		} catch (err) {
			// auth not successful
			this.setState({ isLoginDisabled: false });
			this.onError(err);
		}
	};

	onTwoFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ otp: event.target.value, hasError: false });
	};

	onTwoFactorCancel = () => {
		this.setState({ isTwoFactorInProgress: false });
		this.props.logout();
	};

	onTwoFactorSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.preventDefault();

		try {
			await authClient.verifyOTP(this.state.otp);
			this.props.onLoginSuccess();
		} catch (err) {
			this.onError(err);
		}
	};

	render() {
		const isLoginDisabled = this.state.isLoginDisabled || this.state.username === '' || this.state.password === '';
		const isAuthenticated = this.props.user.isAuthenticated();
		const hasValidAccessToken = !this.props.user.isAccessTokenExpired();
		const { classes } = this.props;

		return (
			<main className={classes.main}>
				<CssBaseline />
				<div ref={this.formContainerRef} className={this.state.hasError ? `${classes.container} ${classes.shake}` : classes.container}>
					<Paper className={classes.paper}>
						<Bayern3Svg className={classes.logo} />
						<Typography component="h1" variant="h5">
							Anmeldung
						</Typography>
						{isAuthenticated ? (
							hasValidAccessToken ? (
								<LogoutContainer user={this.props.user} logout={this.props.logout} />
							) : (
								<WaitForTokenContainer />
							)
						) : this.state.isTwoFactorInProgress ? (
							<TwoFactorContainer
								onTwoFactorChange={this.onTwoFactorChange}
								onTwoFactorCancel={this.onTwoFactorCancel}
								onTwoFactorSubmit={this.onTwoFactorSubmit}
								isTwoFactorDisabled={this.state.otp.length !== 6}
							/>
						) : (
							<LoginContainer onLoginChange={this.onLoginChange} onLoginSubmit={this.onLoginSubmit} isLoginDisabled={isLoginDisabled} />
						)}
					</Paper>
				</div>
				<Snackbar open={this.state.hasError} message={this.state.currentErrorMessage} autoHideDuration={4000} />
			</main>
		);
	}
}

export default withStyles(styles)(Login2FA);
