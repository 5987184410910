import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementImage.scss';
import { DragSource, DropTarget } from 'react-dnd';

const source = {
	beginDrag(props) {
		return { id: props.id };
	},
};

const target = {
	hover(props, monitor) {
		const draggedId = monitor.getItem().id;
		if (draggedId !== props.id) {
			props.moveElement(draggedId, props.id);
		}
	},
};

class ImageGalleryEditorItem extends React.Component {
	render = () => {
		let active = {};
		if (this.props.active) {
			active[styles.previewActive] = true;
		}

		const { isDragging, connectDragSource, connectDropTarget } = this.props;
		const opacity = isDragging ? 0 : 1;

		return connectDragSource(
			connectDropTarget(
				<div
					className={classNames(styles.preview, active)}
					style={{ backgroundImage: 'url(' + this.props.data.content.source + ')', opacity }}
					onClick={this.props.onClick}
				/>
			)
		);
	};
}

export default DropTarget('preview', target, connect => ({
	connectDropTarget: connect.dropTarget(),
}))(
	DragSource('preview', source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}))(ImageGalleryEditorItem)
);
