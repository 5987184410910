/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './AutoSuggest.scss';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { singleFuzzySearch } from '../../commons/fuzzySearch';

class AutoSuggest extends React.Component {
	state = {
		selectedSuggestion: -1,
		suggestions: [],
		value: '',
	};

	onTagKeyPress = e => {
		if (e.target.value === '') {
			this.clearSuggestions();
		} else {
			this.setState({
				suggestions: singleFuzzySearch(e.target.value, this.props.data, o => {
					return o;
				}),
			});
		}

		if (e.which === 38) {
			// key up
			let i = this.state.selectedSuggestion - 1;
			if (i < 0) {
				i = this.state.suggestions.length - 1;
			}
			this.setState({ selectedSuggestion: i });
		} else if (e.which === 40) {
			// key down
			let i = this.state.selectedSuggestion + 1;
			if (i > this.state.suggestions.length - 1) {
				i = 0;
			}
			this.setState({ selectedSuggestion: i });
		} else if (e.which === 13 && e.target.value !== '') {
			if (this.state.selectedSuggestion > -1) {
				this.selectSuggestion();
			} else {
				this.props.onSelect(e.target.value);
				this.clearSuggestions();
				this.setState({ value: '' });
			}
		}
	};

	selectSuggestion = () => {
		this.props.onSelect(this.state.suggestions[this.state.selectedSuggestion].item);
		this.clearSuggestions();
		this.setState({ value: '' });
	};

	clearSuggestions = () => {
		this.setState({
			selectedSuggestion: -1,
			suggestions: [],
		});
	};

	handleChange = e => {
		this.setState({ value: e.target.value });
	};

	onSuggestionClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.selectSuggestion();
	};

	render() {
		const { ...rest } = this.props;
		return (
			<div className={classNames(styles.suggestionsContainer)}>
				<mui.TextField
					{...rest}
					onKeyUp={this.onTagKeyPress}
					onBlur={() => {
						setTimeout(this.clearSuggestions, 100);
					}}
					onChange={this.handleChange}
					value={this.state.value}
				/>
				<ul className={classNames([styles.suggestions])}>
					{this.state.suggestions.map((suggestion, i) => {
						return (
							<li
								className={classNames([styles.suggestion, { [styles.active]: i === this.state.selectedSuggestion }])}
								onClick={this.onSuggestionClick}
								onMouseEnter={() => {
									this.setState({ selectedSuggestion: i });
								}}
								key={suggestion.item}
							>
								{suggestion.item}
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

export default withTheme(AutoSuggest);
