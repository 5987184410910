// regex : FIND :: /^(.*)$/igm   REPLACE :: '$1',
export const tags = [
	'1. FC Nürnberg',
	'2. Bundesliga',
	'ADAC',
	'Adidas',
	'Aigner',
	'Aktien',
	'Aktienkurs',
	'Al Kaida',
	'Allianz',
	'Alpen',
	'Amnesty International',
	'Amoklauf',
	'AOK',
	'Arabischer Frühling',
	'Arbeitslosenzahlen',
	'Ärzte',
	'Aschermittwoch',
	'Asyl ',
	'Audi',
	'Augsburger Panther',
	'Auschwitz',
	'Auschwitz-Prozess',
	'Außenminister',
	'Auto',
	'Autobahn',
	'Autoindustrie',
	'Badminton',
	'Bahn',
	'Banken',
	'Basketball',
	'Bayern LB',
	'Bayernliga',
	'BayWa',
	'Bestechung',
	'Bergsport',
	'BLLV',
	'BMW',
	'BND',
	'Bombe',
	'Boxen',
	'Börsenkurse',
	'BR',
	'Brose Baskets Bamberg',
	'Brauchtum',
	'Brenner',
	'Brose',
	'Brose Baskets',
	'BR-Radltour',
	'Bundeskanzlerin',
	'Bundesliga',
	'Bundespräsident',
	'Bundesrat',
	'Bundestag',
	'Bundesverfassungsgericht',
	'Bundeswehr',
	'Caritas',
	'Cassidian',
	'CDU',
	'CDU/CSU',
	'Champions League',
	'Cisco',
	'Cockpit',
	'CSU',
	'Dallas Mavericks',
	'Datev',
	'DB',
	'Demokratiebewegung',
	'Deutsche Bank',
	'DFB',
	'Diebstahl',
	'Domspatzen',
	'Doping',
	'Drogen',
	'DTM',
	'EHC München',
	'ERC Ingolstadt',
	'Einwanderung',
	'Eishockey',
	'Eltern',
	'Energiewende',
	'Entwicklungshilfe',
	'Erdbeben',
	'EU',
	'Euro',
	'Eurocopter',
	'Europameisterschaft',
	'Facebook',
	'Fahrrad',
	'Fasching',
	'FC Augsburg',
	'FC Ingolstadt 04',
	'FC Würzburger Kickers',
	'FCB',
	'FC Bayern München',
	'FCN',
	'Feuerwehr',
	'Fichtelgebirge',
	'FIFA',
	'Film',
	'Film',
	'Finale',
	'Finanzen',
	'Fitness',
	'Flüchtlinge',
	'Flughafen München',
	'Flughafen Nürnberg',
	'Flughafen Memmingen',
	'Förderschule',
	'Förderung',
	'Formel 1',
	'Forschung',
	'Franck Ribéry',
	'Frauen',
	'Fraunhofer-Gesellschaft',
	'Freie Wähler',
	'Führerschein',
	'Fußball',
	'G7-Gipfel',
	'Gauck',
	'Geld',
	'Gericht',
	'Germanwings',
	'Gesundheit',
	'Gewerkschaft',
	'GfK',
	'Gillamoos',
	'Gipfeltreffen',
	'Gold',
	'Golf',
	'Greuther Fürth',
	'Grüne',
	'Gymnasium',
	'Handball',
	'Hexal',
	'HGAA',
	'Hilfsorganisation',
	'Hitler',
	'Hochwasser',
	'Hockey',
	'Humedica',
	'Homosexualität',
	'HypoVereinsbank',
	'Ikea',
	'Immigranten',
	'Industrie',
	'Infineon',
	'Infratest',
	'Inklusion',
	'Internet',
	'IS',
	'Jahn Regensburg',
	'Jérôme Boateng',
	'Juden',
	'Judo',
	'Jugend',
	'Justiz',
	'Kanzler',
	'Kinder',
	'Kino',
	'Kommunen',
	'Konferenz',
	'Konjunktur',
	'Konzertsaal',
	'Korruption',
	'Kosovo',
	'Kredit',
	'Krieg',
	'Kriegsende',
	'Kuka',
	'Kultur',
	'KZ',
	'Landesbank',
	'Lech',
	'Leichtathletik',
	'Lehrer ',
	'Lesben',
	'Linke',
	'Literatur',
	'LMU',
	'Luftfahrt',
	'Lufthansa',
	'MAN',
	'Manöver',
	'Marketing',
	'Marko Pešić',
	'Massenkarambolage',
	'Maut',
	'Max-Planck-Gesellschaft',
	'Medien',
	'medi bayreuth',
	'Medizin',
	'Merkel',
	'Microsoft',
	'Migration',
	'Mindestlohn',
	'Missbrauch',
	'Missbrauch',
	'Mittelschule',
	'Mord',
	'Mordprozess',
	'Motorrad',
	'Motorsport',
	'Mountainbike',
	'MTU',
	'Munich Re',
	'Nahverkehr',
	'Nationalmannschaft',
	'NawaRo Straubing',
	'Nazi',
	'NBA',
	'Neonazis',
	'Nowitzki',
	'NSA',
	'NSU',
	'Obama',
	'Oktoberfest',
	'Olympia',
	'Olympiapark',
	'Olympische Spiele',
	'Oper',
	'Organspende',
	'Orkan',
	'Osram',
	'Palästinenser',
	'Papst',
	'Pferdesport',
	'Piloten',
	'Pierre Emile Höjbjerg',
	'Plastik',
	'Play-off',
	'Playoff',
	'Pokal',
	'Polizei',
	'Polizeimeldung',
	'Post',
	'Postbank',
	'Presse',
	'Protest',
	'Prozess',
	'Psychiatrie',
	'Puma',
	'Putin',
	'Rad',
	'Radsport',
	'Raumfahrt',
	'Realschule',
	'Rechtsradikale',
	'Referendum',
	'Regen',
	'Regionalliga Bayern',
	'Reise',
	'Rettung',
	'Rettungsschirm',
	'Ringen',
	'S-Bahn',
	'Schaeffler',
	'Schuldenkrise',
	'Schule',
	'Schwimmen',
	'Schwule',
	'Seehofer',
	'Sepp Blatter',
	'Siemens',
	'SKF',
	'Skifahren',
	'Söder',
	'Sommer',
	'Sparkasse',
	'SPD',
	'Spendenaktion',
	'Spionage',
	'Sport aus aller Welt',
	'SpVgg Unterhaching',
	'SpVgg Greuther Fürth',
	'Staatsregierung',
	'Stau',
	'Steinmeier',
	'Stellenabbau',
	'Sternstunden',
	'Straubing Tigers',
	'Straubing-Bogen',
	'Streik',
	'Studium',
	'Sturm',
	'Svetislav Pešić',
	'Tarifverhandlungen',
	'Technik',
	'Tempolimit',
	'Tennis',
	'Terroranschlag',
	'Theater',
	'Tiere',
	'Tirschenreuth',
	'Tischtennis',
	'Tourismus',
	'Tradition',
	'Transparency International',
	'Trendsport',
	'Tripple',
	'Tsipras',
	'TSV 1860 München',
	'TU München',
	'Twitter',
	'UEFA',
	'Umwelt',
	'Unfall',
	'Uni',
	'Union',
	'Universität',
	'Untersuchungsausschuss',
	'Unwetter',
	'Urlaub',
	'Urteil',
	'Varoufakis',
	'Verbraucher',
	'Verfassungsschutz',
	'Verkehr',
	'Verkehr',
	'Volksbank',
	'Volleyball',
	'VSG Coburg/Grub',
	'Wacker Chemie',
	'Waffen',
	'Wassersport',
	'Weltbild',
	'Weltmeisterschaft',
	'Wetter',
	'Wiesn',
	'Windkraft ',
	'Winter',
	'Wintersport',
	'Winterreifen',
	'Wirtschaft',
	'Wirtschaftswachstum',
	'YouTube',
	'Zivilisten',
	'Zoo',
	'Zschäpe',
];
