import { Client, LocalStorage, User } from '@sep/br-auth-client';

import * as config from '../../../config.webcms';

const noop = () => {
	// @ts-ignore
};

/**
 * Mock for SSR
 */
class ServerMockClient {
	authorize(): Promise<User> {
		return new Promise(noop);
	}
	verifyOTP(): Promise<User> {
		return new Promise(noop);
	}
	signOut(): boolean {
		return true;
	}
	getUser(): User {
		return new User();
	}
}

export const authClient =
	process.env.BUILD_TARGET === 'client'
		? new Client({
				endpoint: config.PUBLIC_FACING_ORIGIN_BRAUTH,
				application: config.AUTH_APPLICATION,
				groups: config.AUTH_GROUPS,
				storage: new LocalStorage(),
				verbose: config.IS_DEVELOPMENT,
		  })
		: new ServerMockClient();
