import PropTypes from 'prop-types';
import React from 'react';
import ArticleListItem from '../ArticleList/ArticleListItem.js';
import { DragLayer } from 'react-dnd';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

const DRAG_PREVIEW_WIDTH = 280;
const DRAG_PREVIEW_HEIGHT = 180;

function getItemStyles(props) {
	const { currentOffset } = props;
	if (!currentOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = currentOffset;
	const transform = `translate(${x - DRAG_PREVIEW_WIDTH / 2}px, ${y - DRAG_PREVIEW_HEIGHT / 2}px)`;
	return {
		transform: transform,
		WebkitTransform: transform,
	};
}

class CustomDragLayer extends React.Component {
	renderItem(type, item) {
		switch (type) {
			case 'article':
				return (
					<div
						style={{
							width: DRAG_PREVIEW_WIDTH,
							height: DRAG_PREVIEW_HEIGHT,
							cursor: '-webkit-grabbing',
							position: 'absolute',
						}}
					>
						<ArticleListItem style={{ opacity: 0.9 }} article={item} />
					</div>
				);
		}
	}

	render() {
		const { item, itemType, isDragging } = this.props;
		if (!isDragging) {
			return null;
		}

		return (
			<div style={layerStyles}>
				<div style={getItemStyles(this.props)}>{this.renderItem(itemType, item)}</div>
			</div>
		);
	}
}

CustomDragLayer.propTypes = {
	item: PropTypes.object,
	itemType: PropTypes.string,
	currentOffset: PropTypes.shape({
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
	}),
	isDragging: PropTypes.bool.isRequired,
};

function collect(monitor) {
	return {
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	};
}

export default DragLayer(collect)(CustomDragLayer);
