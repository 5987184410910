/*eslint no-console:0*/

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import styles from './ArticleEditorSidebar.scss';
import PushNotificationHandler from './PushNotificationHandler';
import AutoSuggest from '../AutoSuggest/AutoSuggest.js';
import * as mui from '@material-ui/core';
import * as muip from '@material-ui/pickers';
import { withTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { tags } from './Tags';
import { Link } from 'react-router-dom';
import ElementReorder from '../ElementReorder/ElementReorder';
import update from 'immutability-helper';
import CloseIcon from './close.svg';
import * as config from '../../../config.webapp';

class ArticleEditorSidebar extends React.Component {
	static propTypes = {
		data: PropTypes.object,
		settings: PropTypes.object,
		boards: PropTypes.array,
		onChange: PropTypes.func,
		onSave: PropTypes.func,
		dirty: PropTypes.bool,
		initialState: PropTypes.string,
		articleTypes: PropTypes.array,
		articleStates: PropTypes.array,
		children: PropTypes.array,
	};

	static defaultProps = {
		articleTypes: [
			{ payload: 'media', text: 'Audio/Video' },
			{ payload: 'gallery', text: 'Foto/Galerie' },
			{ payload: 'songbattle', text: 'Songbattle' },
		],
		articleStates: ['draft', 'review', 'published', 'depublished'],
	};

	state = {
		isReorderElementsDialogOpen: false,
		urlValue: '',
	};

	onAddTag = tag => {
		if (tag.toLowerCase && this.props.data.attributes.metadata.tags.indexOf(tag.toLowerCase()) === -1) {
			this.props.onChange(
				update(this.props.data, {
					attributes: { metadata: { tags: { $push: [tag.toLowerCase()] } } },
				})
			);
		}
	};

	removeTag = i => {
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { tags: { $splice: [[i, 1]] } } },
			})
		);
	};

	onDateChange = (key, value) => {
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { doc: { [key]: { $set: value } } } },
			})
		);
	};

	onTimeChange = (refs, e, date) => {
		//merge tempTime's date and date's time
		this.state.tempTime.setHours(date.getHours());
		this.state.tempTime.setMinutes(date.getMinutes());
		// eslint-disable-next-line react/no-string-refs
		this.refs[refs].value = this.state.tempTime;
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { doc: { [refs]: { $set: this.state.tempTime.toISOString() } } } },
			})
		);
	};

	onArticleTypeChange = e => {
		const payload = e.target.value;
		let newElement = {
			element_type: payload,
			content: {},
		};
		if (payload === 'media') {
			newElement.element_subtype = 'video/youtube';
			newElement.metadata = {
				title: '',
				tags: [],
				copyright: '',
			};
		} else if (payload === 'gallery') {
			newElement.content.images = [];
		} else if (payload === 'songbattle') {
			newElement.content.images = [];
			newElement.content.endDate = new Date();
			newElement.content.options = [];
		}
		this.props.onChange(
			update(this.props.data, {
				attributes: { content: { teaser_element: { $set: newElement } } },
			})
		);
	};

	onMetaDataChange = (key, value) => {
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { [key]: { $set: value } } },
			})
		);
	};

	onBoardChange = (id, e) => {
		const checked = e.target.checked;
		if (checked) {
			this.props.onChange(
				update(this.props.data, {
					relationships: { boards: { data: { $push: [{ id: id, type: 'board' }] } } },
				})
			);
		} else {
			const i = this.props.data.relationships.boards.data
				.map(board => {
					return board.id;
				})
				.indexOf(id);
			this.props.onChange(
				update(this.props.data, {
					relationships: { boards: { data: { $splice: [[i, 1]] } } },
				})
			);
		}
	};

	onStateChange = e => {
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { doc: { status: { $set: e.target.value } } } },
			})
		);
	};

	removeDate = date => {
		this.props.onChange(
			update(this.props.data, {
				attributes: { metadata: { doc: { [date]: { $set: undefined } } } },
			})
		);
		// eslint-disable-next-line react/no-string-refs
		this.refs[date].value = undefined;
	};

	moveElement = (dragIndex, hoverIndex) => {
		const { elements } = this.props.data.attributes.content;
		const dragElement = elements[dragIndex];

		this.props.onChange(
			update(this.props.data, {
				attributes: { content: { elements: { $splice: [[dragIndex, 1], [hoverIndex, 0, dragElement]] } } },
			})
		);
	};

	_getSaveButtonTitle = () => {
		const newState = this.props.data.attributes.metadata.doc.status;
		if (newState !== this.props.initialState) {
			if (newState === 'published') {
				return 'Veröffentlichen';
			} else if (newState === 'depublished') {
				return 'Archivieren...';
			}
		}
		return 'Speichern';
	};

	onURLKeyUp = e => {
		if ([8, 37, 38, 39, 40, 46].indexOf(e.which) > -1) {
			return;
		}

		// taken from https://github.com/digitalegarage/br3-coffeemachine/blob/master/services/database.js#L165-L175
		let uid = e.target.value
			.trim()
			.toLowerCase()
			.replace(/ö/g, 'oe')
			.replace(/ä/g, 'ae')
			.replace(/ü/g, 'ue')
			.replace(/ß/g, 'ss')
			.replace(/[^\w\s-]/g, '')
			.replace(/ - /g, ' ')
			.replace(/\s/g, '-')
			.replace(/-+/g, '-');

		this.props.onChange(
			update(this.props.data, {
				id: { $set: uid },
			})
		);

		if (e.target.value !== uid) {
			this.setState({ urlValue: uid });
		}
	};

	toggleReorderElementsDialog = () => {
		this.setState({ isReorderElementsDialogOpen: !this.state.isReorderElementsDialogOpen });
	};

	render() {
		return (
			<div className={classNames(styles.sidebarContainer)}>
				<div className={classNames(styles.sidebar)}>
					<div>
						<label className={classNames(styles.label)}>
							Artikeltyp:
							<mui.Select
								className={styles.select}
								onChange={this.onArticleTypeChange}
								value={this.props.data.attributes.content.teaser_element.element_type}
							>
								{this.props.articleTypes.map((artType, artId) => (
									<mui.MenuItem key={artId} value={artType.payload}>
										{artType.text}
									</mui.MenuItem>
								))}
							</mui.Select>
						</label>
					</div>
					{this.props.data.createdAt ? (
						<div style={{ paddingBottom: 10 }}>
							<strong>URL:</strong>{' '}
							<code>
								<a
									href={`${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.data.id}`}
									target="_blank"
									rel="noopener noreferrer"
								>{`${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.data.id}`}</a>
							</code>
						</div>
					) : (
						<div>
							<h4>Artikel URL</h4>
							<mui.TextField
								fullWidth={true}
								onKeyUp={this.onURLKeyUp}
								onChange={e => {
									this.setState({ urlValue: e.target.value });
								}}
								value={this.state.urlValue}
								placeholder={`${config.PUBLIC_FACING_ORIGIN_WEBAPP}/`}
								style={{ fontFamily: 'monospace' }}
							/>
						</div>
					)}
					<h4>Weiterleitung auf externe Seite</h4>
					<mui.TextField
						fullWidth={true}
						placeholder="https://..."
						onChange={e => this.onMetaDataChange('redirect', e.target.value)}
						value={this.props.data.attributes.metadata.redirect || ''}
					/>
					<h4>meta-Description</h4>
					<mui.TextField
						fullWidth={true}
						placeholder="ansonsten wird der Teasertext verwendet"
						multiline={true}
						onChange={e => this.onMetaDataChange('description', e.target.value)}
						value={this.props.data.attributes.metadata.description}
					/>
					<h4>Tags</h4>
					<div>
						{this.props.data.attributes.metadata.tags.map((t, i) => {
							return (
								<div className={classNames(styles.tag)} key={t}>
									{t}
									<CloseIcon onClick={this.removeTag.bind(this, i)} className={classNames(styles.removeTag)} key={t} />
								</div>
							);
						})}
					</div>
					<AutoSuggest onSelect={this.onAddTag} placeholder="Tags zum Artikel hinzufügen" fullWidth={true} data={tags} />
					<br />
					<div>
						<h4>Facebook</h4>
						<mui.TextField
							fullWidth={true}
							placeholder="Link zum entsprechenden Facebook-Artikel"
							onChange={e => this.onMetaDataChange('facebook_link', e.target.value)}
							value={this.props.data.attributes.metadata.facebook_link}
						/>
					</div>
					<div>
						<mui.FormControlLabel
							label="Empfehlungen anderer Artikel anzeigen"
							control={
								<mui.Checkbox
									checked={this.props.data.attributes.metadata.recommendations_active}
									onChange={this.onMetaDataChange.bind(
										this,
										'recommendations_active',
										!this.props.data.attributes.metadata.recommendations_active
									)}
								/>
							}
						/>
						<mui.FormControlLabel
							label="Banner in diesem Artikel anzeigen"
							control={
								<mui.Checkbox
									checked={this.props.data.attributes.metadata.banner_active}
									onChange={this.onMetaDataChange.bind(this, 'banner_active', !this.props.data.attributes.metadata.banner_active)}
								/>
							}
						/>
					</div>
					<h4>Kategorien auswählen</h4>
					<div>
						Über die Kategorie(n) wird ein Artikel einem oder mehreren Teaserboards zugeordnet. Auf diesen kann der Artikel dann prominent
						gepinnt werden.
						<div className={classNames(styles.twoCols)}>
							{this.props.boards
								.filter(board => board.id !== 'home')
								.map(board => {
									return (
										<mui.FormControlLabel
											label={board.attributes.content.title}
											key={board.id}
											control={
												<mui.Checkbox
													name={board.id}
													checked={
														this.props.data.relationships.boards.data
															.map(relatedBoard => {
																return relatedBoard.id;
															})
															.indexOf(board.id) !== -1
													}
													onChange={this.onBoardChange.bind(this, board.id)}
												/>
											}
										/>
									);
								})}
						</div>
					</div>
					<hr />
					{this.props.data.attributes.content.elements.length > 1 && (
						<div>
							<mui.Button style={{ backgroundColor: '#dedede' }} onClick={this.toggleReorderElementsDialog}>
								Elemente anordnen
							</mui.Button>
							<mui.Dialog
								style={{ position: 'absolute' }}
								open={this.state.isReorderElementsDialogOpen}
								onClose={this.toggleReorderElementsDialog}
								maxWidth="sm"
								fullWidth={true}
							>
								<mui.DialogTitle>Elemente anordnen</mui.DialogTitle>
								<mui.DialogContent>
									<ElementReorder elements={this.props.data.attributes.content.elements} moveElement={this.moveElement} />
								</mui.DialogContent>
								<mui.DialogActions>
									<mui.Button color="primary" onClick={this.toggleReorderElementsDialog}>
										Fertig
									</mui.Button>
								</mui.DialogActions>
							</mui.Dialog>
							<hr />
						</div>
					)}

					<div>
						<div>
							<label className={classNames(styles.label)}>
								Artikel-Status:
								<mui.Select className={styles.select} onChange={this.onStateChange} value={this.props.data.attributes.metadata.doc.status}>
									<mui.MenuItem value="draft">Entwurf</mui.MenuItem>
									<mui.MenuItem value="review">Abgenommen</mui.MenuItem>
									<mui.MenuItem value="published">Veröffentlicht</mui.MenuItem>
									<mui.MenuItem value="depublished">{'Archiviert & Depubliziert'}</mui.MenuItem>
								</mui.Select>
							</label>
						</div>
						{this.props.data.attributes.metadata.doc.status === 'review' && (
							<div>
								<h4>Zeitpunkt der automatischen Veröffentlichung</h4>
								<div className={classNames([styles.contentBox])}>
									<muip.DateTimePicker
										name="auto-publication-date"
										// eslint-disable-next-line react/no-string-refs
										ref="auto_publication_date"
										fullWidth={true}
										value={
											this.props.data.attributes.metadata.doc.auto_publication_date
												? new Date(this.props.data.attributes.metadata.doc.auto_publication_date)
												: null
										}
										placeholder="keine automatische Veröffentlichung"
										minDate={new Date()}
										ampm={false}
										format="D.M.YYYY HH:mm"
										onChange={this.onDateChange.bind(this, 'auto_publication_date')}
									/>
									<div className={classNames(styles.removeDate)}>
										{this.props.data.attributes.metadata.doc.auto_publication_date &&
										this.props.data.attributes.metadata.doc.status !== 'published' ? (
											<a onClick={this.removeDate.bind(this, 'auto_publication_date')}>entfernen</a>
										) : null}
									</div>
									<div className={classNames(styles.removeDate)}>
										{this.props.data.attributes.metadata.doc.auto_depublication_date ? (
											<a onClick={this.removeDate.bind(this, 'auto_depublication_date')}>entfernen</a>
										) : null}
									</div>
									{this.props.data.attributes.metadata.doc.auto_publication_date &&
										this.props.data.attributes.metadata.doc.status === 'draft' && (
											<em>
												<br />
												Die automatische Veröffentlichung funktioniert nur, wenn der Artikel als &quot;Bereit zur Veröffentlichung&quot;
												markiert wurde.
											</em>
										)}
									<br />
								</div>
							</div>
						)}
					</div>
					{this.props.data.attributes.metadata.doc.status === 'published' && this.props.data.id && (
						<PushNotificationHandler data={this.props.data} />
					)}
					{this.props.data.attributes.metadata.doc.status !== 'review' && <hr />}
					<div className={classNames(styles.buttons)}>
						<mui.Button variant="contained" color="default" component={Link} to="/">
							{this.props.initialState === 'depublished' ? 'zurück zum Dashboard' : 'Änderungen verwerfen'}
						</mui.Button>
						<mui.Button
							variant="contained"
							disabled={!this.props.dirty}
							color="primary"
							style={{ flexGrow: 1, marginLeft: 10 }}
							onClick={this.props.onSave}
						>
							{this._getSaveButtonTitle()}
						</mui.Button>
					</div>
					{this.props.dirty && (
						<mui.Button
							onClick={() => {
								this.props.onChange(
									update(this.props.data, { attributes: { metadata: { doc: { retainHistory: { $set: true } } } } }),
									() => {
										this.props.onSave();
									}
								);
							}}
							fullWidth={true}
							className={styles.saveWithoutTimestamp}
						>
							Speichern ohne neuen Zeitstempel
						</mui.Button>
					)}
					<div className={classNames(styles.lastChange)}>
						{this.props.data.createdAt &&
							'Aktualisiert am ' + moment(new Date(this.props.data.updatedAt || this.props.data.createdAt)).format('DD.MM.YYYY HH:mm')}
					</div>
				</div>
				<div className={classNames(styles.editor)}>{this.props.children}</div>
			</div>
		);
	}
}

export default withTheme(ArticleEditorSidebar);
