import classNames from 'classnames';
import update from 'immutability-helper';
import idx from 'idx';
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { themeableForCms } from '../../commons/utils/cms';
// @ts-ignore
import DashboardContainer from '../DashboardContainer/DashboardContainer';
// @ts-ignore
import NavigationEditor from './NavigationEditor';
// @ts-ignore
import NavigationItem from './NavigationItem';
import styles from './NavigationManager.scss';
import { INavigation } from '../../../types/Navigation';

interface IProps {
	data: INavigation;
	handleUpdateNavigationDocument: (document: INavigation) => Promise<void>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialData: any;
	onSave: Function;
}

interface IState {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: { attributes: { content: { items: any } } };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	changed: any[];
	edit: number;
	new: boolean;
}

const createDefaultNavigationDocument = () => ({
	type: 'navigation',
	attributes: {
		content: {
			items: [],
		},
		metadata: {
			doc: {
				status: 'published',
			},
		},
	},
});

class NavigationManager extends React.Component<IProps, IState> {
	static defaultProps = {
		data: createDefaultNavigationDocument(),
	};

	state = {
		data: this.props.data,
		changed: [],
		edit: -1,
		new: false,
	};

	UNSAFE_componentWillMount = () => {
		this.UNSAFE_componentWillReceiveProps(this.props);
	};

	UNSAFE_componentWillReceiveProps = (nextProps: IProps) => {
		this.setState({
			data: { ...nextProps.initialData },
		});
	};

	closeDialog = () => {
		this.setState({
			edit: -1,
			new: false,
		});
	};

	deleteNavItem = i => {
		if (i !== -1 && confirm('Soll der Navigationspunkt wirklich gelöscht werden?')) {
			// @ts-ignore
			let newState = update(this.state, {
				data: {
					attributes: {
						content: {
							items: {
								$splice: [[i, 1]],
							},
						},
					},
				},
				edit: { $set: undefined },
			});

			this.setState(newState);
			if (this.props.onSave) {
				this.props.onSave(newState.data);
			}
		}
	};

	newNavItem = () => {
		this.setState({ new: true });
	};

	saveDialog = navitem => {
		let items;
		if (this.state.edit > -1) {
			items = { $splice: [[this.state.edit, 1, navitem]] };
		} else {
			items = { $push: [navitem] };
		}

		// @ts-ignore
		let newState = update(this.state, {
			data: { attributes: { content: { items: items } } },
			edit: { $set: -1 },
			new: { $set: false },
		});

		this.setState(newState);
		if (this.props.onSave) {
			this.props.onSave(newState.data);
		}
	};

	moveElement = (id: string, afterId: string) => {
		const element = this.state.data.attributes.content.items.filter(c => c.link === id)[0];
		const afterElement = this.state.data.attributes.content.items.filter(c => c.link === afterId)[0];
		const elementIndex = this.state.data.attributes.content.items.indexOf(element);
		const afterIndex = this.state.data.attributes.content.items.indexOf(afterElement);

		this.setState(
			update(this.state, {
				data: {
					attributes: {
						content: {
							items: {
								$splice: [[elementIndex, 1], [afterIndex, 0, element]],
							},
						},
					},
				},
			})
		);
	};

	save = () => {
		if (this.props.onSave) {
			this.props.onSave(this.state.data);
		}
	};

	dataWithoutHomepage = () => {
		return (idx(this.state, _ => _.data.attributes.content.items) || []).filter(item => item.link !== '/r/homepage');
	};

	render() {
		let editor;
		const editorProps = {
			onSave: this.saveDialog,
			onDelete: this.deleteNavItem,
			onCancel: this.closeDialog,
		};

		if (this.state.edit > -1) {
			editor = (
				<NavigationEditor
					{...editorProps}
					data={this.dataWithoutHomepage()[this.state.edit]}
					title="Navigationspunkt bearbeiten"
					i={this.state.edit}
				/>
			);
		} else if (this.state.new) {
			editor = <NavigationEditor {...editorProps} title="Navigationspunkt erstellen" />;
		}
		return (
			<DashboardContainer
				className={styles.navigationManager}
				title="Navigation"
				onAdd={() => {
					this.setState({ new: true });
				}}
			>
				{editor}
				<div className={classNames(styles.items)}>
					<NavigationItem id="homepage" key="homepage" data={{ title: 'Startseite', link: '/r/homepage', visible: true }} />
					{this.dataWithoutHomepage().map((navitem, i) => {
						return (
							<NavigationItem
								id={navitem.link}
								key={navitem.link}
								data={navitem}
								moveElement={this.moveElement}
								onEdit={() => {
									this.setState({ edit: i });
								}}
								onDrop={this.save}
							/>
						);
					})}
				</div>
			</DashboardContainer>
		);
	}
}

export default DragDropContext(HTML5Backend)(themeableForCms(NavigationManager));
