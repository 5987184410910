import { AppBar as MaterialAppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './AppBar.scss';
import SvgHome from './home.svg';

interface IProps {
	title: string;
	onLeftIconButtonClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	logout: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	user: {
		firstname: string;
		lastname: string;
	};
}

export default class AppBar extends Component<IProps> {
	static propTypes = {
		title: PropTypes.string.isRequired,
		logout: PropTypes.func,
		user: PropTypes.shape({
			firstname: PropTypes.string.isRequired,
			lastname: PropTypes.string.isRequired,
			email: PropTypes.string,
		}),
	};

	static defaultProps = {
		title: '',
		logout: () => {},
		user: {
			firstname: '',
			lastname: '',
		},
	};

	state = {};

	static contextTypes = {
		muiTheme: PropTypes.object,
	};

	render() {
		return (
			<MaterialAppBar position="static">
				<Toolbar>
					<IconButton
						color="inherit"
						onClick={this.props.onLeftIconButtonClick}
						title="Startseite"
						aria-label="Startseite"
						style={{ marginLeft: '-12px', marginRight: '1rem' }}
					>
						<SvgHome />
					</IconButton>
					<Typography variant="h6" color="inherit" className={styles.appBarTitle}>
						{this.props.title}
					</Typography>
					<Button
						color="inherit"
						onClick={this.props.logout}
					>{`Abmelden (${this.props.user.firstname} ${this.props.user.lastname})`}</Button>
				</Toolbar>
			</MaterialAppBar>
		);
	}
}
