import React from 'react';
import classNames from 'classnames';
import styles from './MediaPlayerEditor.scss';
import AspectRatioCropper from '../AspectRatioCropper/AspectRatioCropper';
import DropZone from 'react-dropzone';
import idx from 'idx';
import * as mui from '@material-ui/core';
import * as blobUtil from 'blob-util';
import * as config from '../../../config.webapp';
import update from 'immutability-helper';
import { parseYoutubeUrl } from '../../commons/utils';

/**
 * Editor for the content displayed by a Content Element MediaPlayer.
 */
export default class MediaPlayerEditor extends AspectRatioCropper {
	channels = [
		{ title: 'YouTube', type: 'video/youtube', placeholder: 'http://youtube.com/...' },
		{ title: 'Video', type: 'video/mp4', placeholder: 'http://br.de/video.mp4' },
		{ title: 'Audio', type: 'audio/mp3', placeholder: 'http://br.de/audio.mp3' },
	];

	state = {
		selectedRatio: 0,
		selectedType: this.props.data.element_subtype
			? this.channels
					.map(c => {
						return c.type;
					})
					.indexOf(this.props.data.element_subtype) || 0
			: 0,
	};

	dropzoneRef = React.createRef();

	onDrop = files => {
		if (files.length > 0) {
			this.props.onChange('content.poster_image', this._getPosterForURL(URL.createObjectURL(files[0])));
		}
	};

	_getPosterForURL = url => {
		return {
			element_type: 'image',
			content: {
				source: url,
				variants: {
					one_by_one: url,
					three_by_one: url,
					three_by_two: url,
				},
			},
			metadata: {
				tags: [],
				title: '',
				copyright: '',
			},
		};
	};

	onTypeChange = event => {
		const i = event.target.value;
		this.setState({ selectedType: i });
		this.props.onChange('element_subtype', this.channels[i].type);
	};

	onURIChange = e => {
		let content = {
			source: {
				standard: e.target.value,
			},
		};
		const videoId = parseYoutubeUrl(e.target.value).videoId;
		if (videoId) {
			fetch('https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + videoId + '&key=AIzaSyBixiS1OQIoPAHiuR9gWRgfG06f2m2MeuI')
				.then(response => {
					return response.text();
				})
				.then(body => {
					const data = JSON.parse(body).items[0];
					if (data) {
						this.props.onChange('metadata.title', idx(data, _ => _.snippet.title) || '');
						this.props.onChange('metadata.copyright', 'YouTube/' + (idx(data, _ => _.snippet.channelTitle) || ''));
					}
				});
			blobUtil
				.imgSrcToBlob(`${config.SERVICE.youtube}/${videoId}`, 'image/jpeg', { crossOrigin: 'Anonymous' })
				.then(blob => {
					content.poster_image = this._getPosterForURL(blobUtil.createObjectURL(blob));
					this.props.onChange('content', content);
				})
				.catch(err => {
					console.error(err);
				});
		}
		this.props.onChange('content', content);
	};

	_getImagePath = ratio => {
		if (!this.props.data.content.poster_image) {
			return null;
		}
		if (ratio === 0) {
			return this.props.data.content.poster_image.content.source;
		} else {
			return this.props.data.content.poster_image.content.variants[this._ratioToProperty(this.props.ratios[ratio])];
		}
	};

	onCropMove = () => {
		let data = this.cropperRef.current.getCropBoxData();
		data = this.cropperRef.current.getData();
		if (this.state.selectedRatio === 0) {
			this.props.onChange(
				'content.poster_image',
				update(this.props.data.content.poster_image, {
					content: {
						source: {
							$apply: url => {
								return this._buildQueryString(url, data);
							},
						},
					},
				})
			);
		} else {
			this.props.onChange(
				'content.poster_image',
				update(this.props.data.content.poster_image, {
					content: {
						variants: {
							[this._ratioToProperty(this.props.ratios[this.state.selectedRatio])]: {
								$apply: url => {
									return this._buildQueryString(url, data);
								},
							},
						},
					},
				})
			);
		}
	};

	openImagePicker = () => {
		this.dropzoneRef.current.open();
	};

	render() {
		return (
			<div>
				{this.getAspectRatioButtons()}
				<div className={classNames(styles.poster)}>
					<DropZone onDrop={this.onDrop} accept="image/*" ref={this.dropzoneRef}>
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps({
									className: classNames(styles.dropzone),
								})}
								onClick={() => {}}
							>
								<input {...getInputProps()} />
								{idx(this.props, _ => _.data.content.poster_image.content.source)
									? this.getCropper(idx(this.props, _ => _.data.content.poster_image.content.source))
									: 'Es muss ein Vorschaubild für das Video/Audio hochgeladen werden.'}
							</div>
						)}
					</DropZone>
				</div>
				<mui.Button color="secondary" fullWidth={true} onClick={this.openImagePicker}>
					neues Bild hochladen
				</mui.Button>
				<div className={classNames(styles.text)}>
					<mui.Select value={this.state.selectedType} onChange={this.onTypeChange}>
						{this.channels.map((channel, i) => (
							<mui.MenuItem key={channel.type} value={i}>
								{channel.title}
							</mui.MenuItem>
						))}
					</mui.Select>
					<mui.TextField
						placeholder={this.channels[this.state.selectedType].placeholder}
						value={idx(this.props, _ => _.data.content.source.standard) || ''}
						fullWidth={true}
						onChange={this.onURIChange}
						style={{ marginLeft: '1rem' }}
					/>
				</div>
			</div>
		);
	}
}
