import * as mui from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import * as config from '../../../config.webcms';

const { FIREBASE: FIREBASE_CONFIG } = config;

interface IProps {
	data: {
		id: string;
		attributes: {
			content: {
				title: string;
				headline: string;
			};
		};
	};
}

interface IState {
	body: string;
	isDialogOpen: boolean;
	title: string;
}

export default class PushNotificationHandler extends React.Component<IProps, IState> {
	state = {
		body: this.props.data.attributes.content.title,
		isDialogOpen: false,
		title: this.props.data.attributes.content.headline,
	};

	pushModal = null;

	onShowDialog = () => this.setState({ isDialogOpen: true });
	onHideDialog = () => this.setState({ isDialogOpen: false });

	handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
		this.setState({ ...this.state, [name]: event.target.value });
	};

	onSendNotification = () => {
		fetch(FIREBASE_CONFIG.url, {
			body: JSON.stringify({
				notification: {
					article_id: this.props.data.id,
					body: this.state.body,
					sound: FIREBASE_CONFIG.sound,
					title: this.state.title,
				},
				to: `/topics/${FIREBASE_CONFIG.topic}`,
			}),
			headers: {
				Authorization: `key=${FIREBASE_CONFIG.key}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
		})
			.then(res => res.json())
			.then(() => {
				this.onHideDialog();
			});
	};

	render() {
		return (
			<div>
				<mui.Button style={{ backgroundColor: '#dedede', width: '100%' }} onClick={this.onShowDialog}>
					Push-Mitteilung versenden...
				</mui.Button>
				<mui.Dialog open={this.state.isDialogOpen} onClose={this.onHideDialog}>
					<mui.DialogTitle>Push-Mitteilung versenden</mui.DialogTitle>
					<mui.DialogContent>
						<mui.TextField onChange={this.handleChange('title')} fullWidth={true} label="Titel" defaultValue={this.state.title} />
						<mui.TextField onChange={this.handleChange('body')} fullWidth={true} label="Nachricht" defaultValue={this.state.body} />
					</mui.DialogContent>
					<mui.DialogActions>
						<mui.Button onClick={this.onHideDialog}>Abbrechen</mui.Button>
						<mui.Button color="primary" onClick={this.onSendNotification}>
							Senden
						</mui.Button>
					</mui.DialogActions>
				</mui.Dialog>
			</div>
		);
	}
}
