import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementImage.scss';
import DropZone from 'react-dropzone';
import ImageGalleryEditorItem from './ImageGalleryEditorItem';
import AspectRatioCropper from '../AspectRatioCropper/AspectRatioCropper';
import idx from 'idx';
import * as mui from '@material-ui/core';
import update from 'immutability-helper';
import SvgPlusCircle from './plus-circle.svg';

/**
 * Editor for the images contained in a ContentElementImage component.
 */

export default class ImageGalleryEditor extends AspectRatioCropper {
	static propTypes = {
		images: PropTypes.array,
		isTeaser: PropTypes.bool,
		ratios: PropTypes.array,
		onChange: PropTypes.func.isRequired,
	};

	state = {
		selectedRatio: 0,
		selectedImage: this.props.images.length > 0 ? 0 : -1,
	};

	onDrop = files => {
		files = files.map(image => {
			const imageUrl = URL.createObjectURL(image);
			const obj = {
				content: {
					source: imageUrl,
				},
				element_type: 'image',
				metadata: {
					title: '',
					copyright: '',
					tags: [],
				},
			};

			// use standard image for all variants by default
			obj.content.variants = {
				three_by_one: imageUrl,
				three_by_two: imageUrl,
				one_by_one: imageUrl,
			};

			return obj;
		});

		const imageToSelect = this.props.images.length;

		this.props.onChange(
			update(this.props, {
				images: { $push: files },
			}).images
		);

		this.setState(
			update(this.state, {
				selectedImage: { $set: imageToSelect },
			})
		);
	};

	_setInitalCropBox = () => {
		this.onCropMove();
	};

	onCropMove = () => {
		const data = this.cropperRef.current ? this.cropperRef.current.getData() : null;
		if (data) {
			if (
				(this.props.isTeaser && this.props.ratios[this.state.selectedRatio] === '2:1') ||
				(!this.props.isTeaser && this.props.ratios[this.state.selectedRatio] === '16:9')
			) {
				this.props.onChange(
					update(this.props.images, {
						[this.state.selectedImage]: {
							content: {
								source: {
									$apply: url => {
										return this._buildQueryString(url, data);
									},
								},
							},
						},
					})
				);
			} else {
				this.props.onChange(
					update(this.props.images, {
						[this.state.selectedImage]: {
							content: {
								variants: {
									[this._ratioToProperty(this.props.ratios[this.state.selectedRatio])]: {
										$apply: url => {
											return this._buildQueryString(url, data);
										},
									},
								},
							},
						},
					})
				);
			}
		}
	};

	selectImage = i => {
		this.setState({
			selectedImage: i,
			selectedRatio: 0,
		});
	};

	moveElement = (id, afterId) => {
		const element = this.props.images.filter(c => c.content.source === id)[0];
		const afterElement = this.props.images.filter(c => c.content.source === afterId)[0];
		const elementIndex = this.props.images.indexOf(element);
		const afterIndex = this.props.images.indexOf(afterElement);

		this.setState(
			update(this.state, {
				selectedImage: { $set: afterIndex },
			})
		);

		this.props.onChange(
			update(this.props, {
				images: {
					$splice: [[elementIndex, 1], [afterIndex, 0, element]],
				},
			}).images
		);
	};

	metaChange = (field, e) => {
		let newImages = this.props.images;
		newImages[this.state.selectedImage].metadata[field] = e.target.value;
		this.props.onChange(newImages);
	};

	_getImagePath = ratio => {
		if (this.state.selectedImage === -1) {
			return null;
		}
		if ((this.props.isTeaser && this.props.ratios[ratio] === '2:1') || (!this.props.isTeaser && this.props.ratios[ratio] === '16:9')) {
			return this.props.images[this.state.selectedImage].content.source;
		} else {
			return this.props.images[this.state.selectedImage].content.variants[this._ratioToProperty(this.props.ratios[ratio])];
		}
	};

	deleteImage = () => {
		if (this.state.selectedImage > -1) {
			let newSelectedImage = this.state.selectedImage - 1;
			if (this.props.images.length > 1 && newSelectedImage < 0) {
				newSelectedImage = 0;
			}

			this.setState(
				update(this.state, {
					selectedImage: { $set: newSelectedImage },
				})
			);

			this.props.onChange(
				update(this.props, {
					images: {
						$splice: [[this.state.selectedImage, 1]],
					},
				}).images
			);
		}
	};

	render = () => {
		return (
			<div className={classNames(styles.editor)}>
				{this.getAspectRatioButtons()}
				<div className={classNames(styles.cropperPlaceholder)}>
					{this.state.selectedImage > -1 ? this.getCropper(this.props.images[this.state.selectedImage].content.source) : null}
				</div>
				<mui.TextField
					placeholder="Bildunterschrift"
					multiline={true}
					fullWidth={true}
					disabled={this.state.selectedImage < 0 || (this.props.isTeaser && this.state.selectedImage === 0)}
					value={
						this.state.selectedImage === 0 && this.props.isTeaser
							? 'Titel des Artikels wird bei erstem Bild angezeigt'
							: idx(this.props, _ => _.images[this.state.selectedImage].metadata.title) || ''
					}
					onChange={this.metaChange.bind(this, 'title')}
				/>
				<mui.TextField
					placeholder="Copyright"
					style={{ width: '70%' }}
					disabled={this.state.selectedImage < 0}
					value={this.state.selectedImage < 0 ? '' : this.props.images[this.state.selectedImage].metadata.copyright}
					onChange={this.metaChange.bind(this, 'copyright')}
				/>
				<mui.Button
					color="secondary"
					style={{ width: '30%', marginBottom: 20 }}
					onClick={this.deleteImage}
					disabled={this.state.selectedImage < 0}
				>
					Bild Löschen
				</mui.Button>
				<div className={classNames(styles.imageRow)}>
					{this.props.images.map((img, i) => {
						return (
							<ImageGalleryEditorItem
								data={img}
								key={img.content.source}
								id={img.content.source}
								onClick={this.selectImage.bind(this, i)}
								moveElement={this.moveElement}
								active={this.state.selectedImage === i}
							/>
						);
					})}
					<DropZone onDrop={this.onDrop} accept="image/*">
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps({
									className: classNames(styles.preview, styles.add),
								})}
							>
								<input {...getInputProps()} />
								<SvgPlusCircle />
							</div>
						)}
					</DropZone>
				</div>
			</div>
		);
	};
}
