/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './NavigationManager.scss';
import { DragSource, DropTarget } from 'react-dnd';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import URI from 'urijs';
import { Link } from 'react-router-dom';
import SvgMenu from './menu.svg';
import SvgHome from './home.svg';
import SvgPencil from './pencil.svg';
import SvgViewQuilt from './view-quilt.svg';

const BOARD_URL = '/r/';

const source = {
	beginDrag(props) {
		return { link: props.id };
	},
};

const target = {
	hover(props, monitor) {
		const draggedId = monitor.getItem().link;
		if (draggedId !== props.id) {
			props.moveElement(draggedId, props.id);
		}
	},

	drop(props) {
		props.onDrop();
	},
};

class NavigationItem extends React.Component {
	static defaultProps = {
		data: {
			link: '',
			title: '',
			visible: true,
		},
	};

	_isTeaserBoard = () => {
		return this.props.data.link === '/' || URI.parse(this.props.data.link).path.indexOf(BOARD_URL) === 0;
	};

	_isHome = () => {
		return this.props.data.link === '/r/homepage';
	};

	render() {
		const { data, isDragging, connectDragSource, connectDropTarget } = this.props;
		const opacity = isDragging ? 0 : 1;
		var title = <strong>{data.title}</strong>;
		if (this._isTeaserBoard()) {
			title = <Link to={`/board/${URI.parse(this.props.data.link).path.substring(BOARD_URL.length)}`}>{title}</Link>;
		}
		var item = (
			<div style={{ opacity }} className={classNames(styles.item)}>
				{this.props.moveElement && <SvgMenu className={classNames(styles.handle)} />}
				{!this.props.moveElement && <SvgHome className={classNames(styles.home)} />}
				<div className={classNames(styles.itemname)}>
					{title}
					{!this._isHome() ? data.link : null}
				</div>
				{this.props.moveElement && (
					<mui.IconButton style={{ minWidth: 48 }} onClick={this.props.onEdit}>
						<SvgPencil className={classNames(styles.edit)} />
					</mui.IconButton>
				)}
				{this._isTeaserBoard() && (
					<mui.IconButton
						component={Link}
						to={`/board/${URI.parse(this.props.data.link).path.substring(BOARD_URL.length)}`}
						style={{ marginLeft: '1rem' }}
					>
						<SvgViewQuilt className={classNames(styles.teaserboard)} />
					</mui.IconButton>
				)}
			</div>
		);

		if (this.props.moveElement) {
			return connectDragSource(connectDropTarget(item));
		} else {
			return item;
		}
	}
}

export default DropTarget('navitem', target, connect => ({
	connectDropTarget: connect.dropTarget(),
}))(
	DragSource('navitem', source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}))(withTheme(NavigationItem))
);
