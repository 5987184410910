/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './DashboardContainer.scss';
import * as mui from '@material-ui/core';
import { themeableForCms } from '../../commons/utils/cms';
import SvgPlus from './plus.svg';

class DashboardContainer extends React.Component {
	render() {
		return (
			<div className={classNames(this.props.className, styles.container)}>
				<div className={classNames(styles.header)}>
					<h2>{this.props.title}</h2>
					<mui.Fab color="secondary" onClick={this.props.onAdd} style={{ maxWidth: 40, maxHeight: 40, marginTop: 10, marginRight: 10 }}>
						<SvgPlus className={classNames(styles.icon)} />
					</mui.Fab>
				</div>

				{this.props.children}
			</div>
		);
	}
}

export default themeableForCms(DashboardContainer);
