import React from 'react';
import ArticleListItem from './ArticleListItem.js';
import { Link } from 'react-router-dom';

export default class ClickableArticleListItem extends React.Component {
	render() {
		const { style, article, detailLevel } = this.props;
		return (
			<Link to={'/article/' + article.id + '?status=all'} style={{ color: 'inherit', textDecoration: 'none' }}>
				<ArticleListItem {...{ style, article, detailLevel }} />
			</Link>
		);
	}
}
