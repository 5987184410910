/*eslint no-console:0*/

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import styles from './BannerManager.scss';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import DropZone from 'react-dropzone';
import update from 'immutability-helper';
import SvgPlusCircle from './plus-circle.svg';
import uuid from 'uuid/v4';

/**
 * Editor-Dialog for banners that are displayed next to the articles' content.
 */
class BannerEditor extends React.Component {
	static defaultProps = {
		data: {
			id: uuid(),
			type: 'banner',
			attributes: {
				content: {
					image_source: '',
					link: '',
					title: '',
					tags: [],
				},
				metadata: {
					doc: {
						status: 'draft',
					},
				},
			},
		},
	};

	static propTypes = {
		data: PropTypes.object.isRequired,
		title: PropTypes.string.isRequired,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
	};

	state = {
		data: this.props.data,
		showEditDialog: false,
		isLoading: false,
	};

	componentDidMount = () => {
		this.setState({ showEditDialog: true });
	};

	componentWillUnmount = () => {
		this.setState({ showEditDialog: false });
	};

	onSave = async () => {
		this.setState({ isLoading: true });
		if (this.state.data.attributes.content.title.length > 0 && this.state.data.attributes.content.link.length > 0) {
			await this.props.onSave(this.state.data);
		}
		this.setState({ isLoading: false });
	};

	onCancel = () => {
		this.setState({ showEditDialog: false });
		// wait for dialog to disapper
		setTimeout(() => {
			this.props.onCancel();
		}, 500);
	};

	onChange = (key, e) => {
		this.setState(
			update(this.state, {
				data: { attributes: { content: { [key]: { $set: e.target.value } } } },
			})
		);
	};

	toggleBanner = (e, state) => {
		if (state && !this.state.data.attributes.content.image_source) {
			alert('Es muss erst eine Grafik hochgeladen werden, bevor der Banner aktiviert werden kann.');
		} else {
			this.setState(
				update(this.state, {
					data: { attributes: { metadata: { doc: { status: { $set: state ? 'published' : 'draft' } } } } },
				})
			);
		}
	};

	onDrop = files => {
		this.setState(
			update(this.state, {
				data: { attributes: { content: { image_source: { $set: URL.createObjectURL(files[0]) } } } },
			})
		);
	};

	render() {
		return (
			<mui.Dialog open={this.state.showEditDialog} maxWidth="sm" fullWidth={true}>
				<mui.DialogTitle>{this.props.title}</mui.DialogTitle>
				<mui.DialogContent>
					<mui.Grid container>
						<mui.Grid item xs={5}>
							<DropZone onDrop={this.onDrop} accept="image/*">
								{({ getRootProps, getInputProps }) => (
									<div
										{...getRootProps({
											className: classNames(styles.upload),
											style: { backgroundImage: `url(${this.state.data.attributes.content.image_source})` },
										})}
									>
										<input {...getInputProps()} />
										{!this.state.data.attributes.content.image_source && (
											<div>
												<SvgPlusCircle />
												<br />
												Grafik hochladen
											</div>
										)}
									</div>
								)}
							</DropZone>
						</mui.Grid>
						<mui.Grid item xs={7}>
							<mui.TextField
								disabled={this.state.isLoading}
								label="Name des Banners"
								value={this.state.data.attributes.content.title}
								onChange={this.onChange.bind(this, 'title')}
								fullWidth={true}
							/>
							<mui.TextField
								disabled={this.state.isLoading}
								style={{ marginBottom: 25 }}
								label="URL für den Banner"
								placeholder="https://..."
								value={this.state.data.attributes.content.link}
								onChange={this.onChange.bind(this, 'link')}
								fullWidth={true}
								margin="normal"
							/>
							<mui.FormControlLabel
								control={
									<mui.Switch
										defaultChecked={this.state.data.attributes.metadata.doc.status === 'published'}
										onChange={this.toggleBanner}
										disabled={this.state.isLoading || !this.state.data.attributes.content.image_source}
										label="Banner aktiv"
									/>
								}
								label="Banner aktiv"
							/>

							<mui.Button
								disabled={this.state.isLoading}
								style={{ float: 'right' }}
								color="secondary"
								onClick={() => this.props.onDelete(this.props.data)}
							>
								Löschen
							</mui.Button>
						</mui.Grid>
					</mui.Grid>
				</mui.DialogContent>
				<mui.DialogActions>
					<mui.Button disabled={this.state.isLoading} onClick={this.onCancel}>
						Abbrechen
					</mui.Button>
					<mui.Button disabled={this.state.isLoading} color="primary" onClick={this.onSave}>
						Speichern
					</mui.Button>
				</mui.DialogActions>
			</mui.Dialog>
		);
	}
}

export default withTheme(BannerEditor);
