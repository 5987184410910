import * as mui from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import wording from '../../commons/wording';
import AutoAspectImage from '../AutoAspectImage/AutoAspectImage.js';
import styles from './ArticleListItem.scss';

export default class ArticleListItem extends React.Component {
	static propTypes = {
		article: PropTypes.object.isRequired,
		detailLevel: PropTypes.number,
		style: PropTypes.object,
	};

	render() {
		const { article } = this.props;

		// strip HTML tags from element
		var element = document.createElement('span');
		element.innerHTML = article.attributes.content.teaser_text;
		var summary = element.textContent || element.innerText || '';

		let children = (
			<div>
				<div className={classNames(styles.picture, 'picture')}>
					{/* {this.props.article.piwik ? (
						<div className={classNames(styles.piwik)} title={`${this.props.article.piwik.nb_visits} Besucher in den letzten 7 Tagen`}>
							<EyeIcon />
							{this.props.article.piwik.nb_visits}
						</div>
					) : null} */}
					<AutoAspectImage {...article.attributes.content.teaser_image.content} />
					<div className={classNames(styles.header)}>
						<h3 className={classNames(styles.headline)}>{article.attributes.content.headline}</h3>
						<h2 className={classNames(styles.title)}>{article.attributes.content.title}</h2>
					</div>
				</div>
				{this.props.detailLevel > 0 ? (
					<div className={classNames(styles.publishingInfo)}>
						<div className={classNames(styles.status, styles[article.attributes.metadata.doc.status])}>
							{wording[article.attributes.metadata.doc.status]}
						</div>
						<div className={classNames(styles.author)}>
							{article.attributes.metadata.author.first_name} {article.attributes.metadata.author.last_name}
						</div>
						<div className={classNames(styles.timestamp)}>{moment(article.updatedAt || article.createdAt).calendar()}</div>
					</div>
				) : (
					''
				)}
				{this.props.detailLevel > 1 ? (
					<div className={classNames(styles.summary)}>
						<p>{summary}</p>
					</div>
				) : (
					''
				)}
				<div className={classNames(styles.tags)}>
					{article.attributes.metadata.tags.map(tag => (
						<span key={tag} className={classNames(styles.tag)}>
							{tag}
						</span>
					))}
				</div>
			</div>
		);

		return (
			<div style={this.props.style} className={styles.ArticleListItem}>
				<mui.Paper>{children}</mui.Paper>
			</div>
		);
	}
}
