import React from 'react';
import styles from './Editor.scss';
import TeaserBox from '../TeaserBox/TeaserBox';
import Summary from '../Summary/Summary.js';
import ArticleEditorSidebar from '../ArticleEditorSidebar/ArticleEditorSidebar';
import { ContentCreator } from '../../components.webcms';
import ContentElements from '../../commons/contentElements';
import FacebookComments from '../FacebookComments/FacebookComments';
import Banner from '../Banner/Banner';
import Recommendations from '../Recommendations/Recommendations';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import * as mui from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import update from 'immutability-helper';
import SvgClose from './close.svg';

/**
 * Editor for articles.
 */
class Editor extends React.Component {
	state = {
		movable: false,
		dirty: false,
		saveMessage: '',
		isSnackbarVisible: false,
		isDialogVisible: false,
	};

	static defaultProps = {
		boards: [],
	};

	componentDidMount() {
		const data = JSON.parse(JSON.stringify(this.props.initialData));
		this.setState({ data });
	}

	componentDidUpdate = prevProps => {
		if (prevProps.logs.length < this.props.logs.length && this.props.logs.length) {
			if (this.props.logs[this.props.logs.length - 1].code == 'OK') {
				this.setState({ isSnackbarVisible: true });
			} else {
				this.setState({ isDialogVisible: true });
			}
		}
		if (JSON.stringify(prevProps.initialData) !== JSON.stringify(this.props.initialData)) {
			const data = JSON.parse(JSON.stringify(this.props.initialData));
			this.setState({ data, dirty: false });
		}
	};

	movable = movable => {
		this.setState({ movable });
	};

	addElement(element) {
		const contentElement = { ...ContentElements[element].defaultProps.data };
		contentElement.id = Math.random().toString(36);
		this.setState(
			update(this.state, {
				data: { attributes: { content: { elements: { $push: [contentElement] } } } },
				dirty: { $set: true },
			})
		);
	}

	updateContent = (key, value) => {
		this.setState(
			update(this.state, {
				data: { attributes: { content: { [key]: { $set: value } } } },
				dirty: { $set: true },
			})
		);
	};

	updateTeaser = data => {
		this.setState(
			update(this.state, {
				data: {
					attributes: {
						content: {
							teaser_element: { $set: data },
						},
					},
				},
				dirty: { $set: true },
			})
		);
	};

	updateElement = (id, data) => {
		let i = this.state.data.attributes.content.elements.map(e => e.id).indexOf(id);
		this.setState(
			update(this.state, {
				data: { attributes: { content: { elements: { [i]: { $set: data } } } } },
				dirty: { $set: true },
			})
		);
	};

	deleteElement = id => {
		let i = this.state.data.attributes.content.elements.map(e => e.id).indexOf(id);
		this.setState(
			update(this.state, {
				data: { attributes: { content: { elements: { $splice: [[i, 1]] } } } },
				dirty: { $set: true },
			})
		);
	};

	moveElement = (dragIndex, hoverIndex) => {
		const { elements } = this.state.data.attributes.content;
		const dragElement = elements[dragIndex];

		this.setState(
			update(this.state, {
				data: { attributes: { content: { elements: { $splice: [[dragIndex, 1], [hoverIndex, 0, dragElement]] } } } },
				dirty: { $set: true },
			})
		);
	};

	generateUpdateContentHandler = key => {
		return value => {
			this.updateContent(key, value);
		};
	};

	generateUpdateElementHandler = id => {
		return value => {
			this.updateElement(id, value);
		};
	};

	save = () => {
		if (
			this.state.data.attributes.metadata.doc.status !== 'depublished' ||
			confirm('Soll der Artikel wirklich depubliziert werden? Danach kann er nicht mehr verändert werden.')
		) {
			this.props.onChange(this.state.data);
		}
	};

	articleChange = (data, cb) => {
		this.setState(
			{
				data: data,
				dirty: true,
			},
			cb
		);
	};

	render() {
		if (!this.state.data || !this.state.data.attributes) {
			return null;
		}

		let teaser = React.createElement(ContentElements[this.state.data.attributes.content.teaser_element.element_type], {
			onChange: this.updateTeaser,
			onHeadlineChange: this.generateUpdateContentHandler('headline'),
			headline: this.state.data.attributes.content.headline,
			onTitleChange: this.generateUpdateContentHandler('title'),
			title: this.state.data.attributes.content.title,
			onTeaserImageChange: this.generateUpdateContentHandler('teaser_image'),
			isTeaser: true,
			data: this.state.data.attributes.content.teaser_element,
		});

		return (
			<div className="editor">
				<ArticleEditorSidebar
					data={{ ...this.state.data }}
					boards={this.props.boards}
					onChange={this.articleChange}
					onSave={this.save}
					initialState={this.props.initialData.attributes.metadata.doc.status}
					valid={true}
					dirty={this.state.dirty}
					settings={this.props.settings}
					toggleSettings={this.props.toggleSettings}
				>
					{teaser}
					<div className="container">
						<main>
							<TeaserBox
								onChange={this.generateUpdateContentHandler('teaser_text')}
								htmlText={this.state.data.attributes.content.teaser_text}
								date={this.state.data.updatedAt || this.state.data.createdAt || new Date()}
							/>
							{this.state.data.attributes.content.elements.map((e, i) => {
								const element = React.createElement(ContentElements[e.element_type], {
									key: i,
									data: e,
									onChange: value => {
										return this.updateElement(e.id, value);
									},
								});
								return (
									<div key={i} className={styles.contentElement}>
										<div onClick={this.deleteElement.bind(this, e.id)} className={styles.delete}>
											<SvgClose />
										</div>
										{element}
									</div>
								);
							})}
							<ContentCreator onAddElement={this.addElement.bind(this)} />
							<Summary text={this.state.data.attributes.content.summary} onChange={this.generateUpdateContentHandler('summary')} />
							{this.state.data.attributes.metadata.facebook_link && <FacebookComments link="" data={{}} />}
						</main>
						<aside id="sidebar">{this.state.data.attributes.metadata.banner_active && <Banner data={this.props.banner} />}</aside>
						<aside id="recommendations">
							{this.state.data.attributes.metadata.recommendations_active && <Recommendations data={this.props.recommendations} />}
						</aside>
					</div>
				</ArticleEditorSidebar>
				{this.props.logs.length > 0 && (
					<mui.Snackbar
						onClose={() => this.setState({ isSnackbarVisible: false })}
						open={this.state.isSnackbarVisible}
						message={this.props.logs[this.props.logs.length - 1].title}
						autoHideDuration={5000}
					/>
				)}
				<div className={styles.dialog}>
					{this.props.logs.length > 0 && (
						<mui.Dialog open={this.state.isDialogVisible} onClose={() => this.setState({ isDialogVisible: false })}>
							<mui.DialogTitle>{this.props.logs[this.props.logs.length - 1].title}</mui.DialogTitle>
							<mui.DialogContent>{this.props.logs[this.props.logs.length - 1].detail}</mui.DialogContent>
							<mui.DialogActions>
								<mui.Button color="primary" onClick={() => this.setState({ isDialogVisible: false })}>
									Fertig
								</mui.Button>
							</mui.DialogActions>
						</mui.Dialog>
					)}
				</div>
			</div>
		);
	}
}

export default DragDropContext(HTML5Backend)(withTheme(Editor));
