import React from 'react';
import * as mui from '@material-ui/core';
import Cropper from './Cropper';
import PropTypes from 'prop-types';

class AspectRatioCropper extends React.Component {
	static defaultProps = {
		isTeaser: false,
		ratios: ['2:1', '3:1', '1:1', '3:2'],
	};

	static propTypes = {
		ratios: PropTypes.arrayOf(PropTypes.string),
		isTeaser: PropTypes.bool,
	};

	cropperRef = React.createRef();

	_parseRatio = string => {
		return eval(string.replace(':', '/'));
	};

	_ratioToProperty = string => {
		const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
		const a = string.split(':');
		return numbers[parseInt(a[0])] + '_by_' + numbers[parseInt(a[1])];
	};

	_loadCropBox = ratio => {
		let data = this._getImagePath(ratio);

		if (data && (data.indexOf('#') > -1 || data.indexOf('?') > -1)) {
			if (data.indexOf('#') > -1) {
				data = data.split('#');
			} else {
				data = data.split('?');
			}
			const urlParams = new URLSearchParams(data[1]);
			const parsedUrlParams = {};
			urlParams.forEach((value, key) => {
				if (key === 'rect') {
					const rect = value.split(',').map(elem => parseInt(elem));
					parsedUrlParams.x = rect[0];
					parsedUrlParams.y = rect[1];
					parsedUrlParams.width = rect[2];
					parsedUrlParams.height = rect[3];
				} else {
					parsedUrlParams[key] = parseInt(value);
				}
			});

			// setTimeout waits for the cropper to have finished rendering
			window.setTimeout(() => {
				this.cropperRef.current.setData(parsedUrlParams);
			});
		} else {
			if (this._setInitalCropBox) {
				this._setInitalCropBox();
			}
		}
	};

	_buildQueryString = (url, data) => {
		let separator = url.indexOf('http') == 0 ? '?' : '#';
		url = url.split('#')[0].split('?')[0];
		return `${url}${separator}rect=${Math.max(0, Math.floor(data.x))},${Math.max(0, Math.floor(data.y))},${Math.max(
			0,
			Math.floor(data.width)
		)},${Math.max(0, Math.floor(data.height))}&_naturalWidth=${this.cropperRef.current.getImageData().naturalWidth}&_naturalHeight=${
			this.cropperRef.current.getImageData().naturalHeight
		}`;
	};

	cropperReady = () => {
		this._loadCropBox(this.state.selectedRatio);
	};

	onAspectRatioChange = i => {
		this.setState({
			selectedRatio: i,
		});
		this._loadCropBox(i);
	};

	getAspectRatioButtons = () => {
		let aspectRatios;
		if (this.props.isTeaser) {
			aspectRatios = (
				<div>
					{this.props.ratios.map((e, i) => {
						return (
							<mui.Button
								key={i}
								color={this.state.selectedRatio === i ? 'secondary' : 'default'}
								onClick={this.onAspectRatioChange.bind(this, i)}
							>
								{e}
							</mui.Button>
						);
					})}
				</div>
			);
		}

		return aspectRatios;
	};

	getCropper = src => {
		let cropper;
		if (src) {
			//remove cropping information from image
			src = src.split('#')[0].split('?')[0];
			cropper = (
				<Cropper
					ref={this.cropperRef}
					src={src}
					style={{ height: 360, width: '100%' }}
					aspectRatio={this._parseRatio(this.props.ratios[this.state.selectedRatio])}
					zoomable={false}
					cropmove={this.onCropMove}
					ready={this.cropperReady}
					autoCropArea={1}
				/>
			);
		}
		return cropper;
	};
}

export default AspectRatioCropper;
