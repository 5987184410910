import { User } from '@sep/br-auth-client';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { authClient } from './authClient';
import Login2FA from './Login2FA';

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: ({ user, logout }: { user: User; logout: () => void }) => any;
}

interface IState {
	isLoggedIn: boolean;
}

/**
 * Makes sure that the cms is only rendered if the user is logged in and has a valid access token.
 */
class AuthenticationProvider extends React.Component<IProps, IState> {
	state = {
		isLoggedIn: AuthenticationProvider.isLoggedIn(authClient.getUser()),
	};

	/**
	 * Performs the logout of the user.
	 */
	logout = () => {
		authClient.signOut();
		authClient.getUser().reset();
		authClient.getUser().clearClientAndAuthToken();
		this.setState({ isLoggedIn: AuthenticationProvider.isLoggedIn(authClient.getUser()) });
	};

	/**
	 * Checks if the user is authenticated and has a valid access token.
	 */
	static isLoggedIn = (user?: User) => !!user && user.isAuthenticated() && !user.isAccessTokenExpired();

	/**
	 * Checks if the user is logged in. If the user is already authenticated but has no valid access token
	 * the authClient will take care of that. So there's another check every 500ms.
	 */
	checkLoginState = () => {
		if (!this.state.isLoggedIn) {
			const user = authClient.getUser();
			if (user.isAuthenticated() && user.isAccessTokenExpired()) {
				setTimeout(() => this.setState({ isLoggedIn: AuthenticationProvider.isLoggedIn(authClient.getUser()) }), 500);
			}
		}
	};

	componentDidMount() {
		this.checkLoginState();
	}

	componentDidUpdate() {
		this.checkLoginState();
	}

	/**
	 * Updates the login state when the user has successful passed the login screen.
	 */
	onLoginSuccess = () => {
		this.setState({ isLoggedIn: AuthenticationProvider.isLoggedIn(authClient.getUser()) });
	};

	render() {
		const user = authClient.getUser();

		if (this.state.isLoggedIn) {
			return this.props.children({ user, logout: this.logout });
		} else {
			return <Login2FA logout={this.logout} onLoginSuccess={this.onLoginSuccess} user={user} />;
		}
	}
}

export default hot(AuthenticationProvider);
