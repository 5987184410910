import PropTypes from 'prop-types';
import React from 'react';
import * as mui from '@material-ui/core';
import idx from 'idx';
import * as muip from '@material-ui/pickers';
import moment from 'moment';
import * as config from '../../../config.webapp';

const NUMBER_OF_SONGS = 2;

export default class SongBattleEditor extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
		data: PropTypes.object,
	};

	onChange = (field, i, value) => {
		const options = idx(this.props, _ => _.data.content.options[i]) || {};

		if (i > -1) {
			this.props.onChange((options[field] = value));
		} else {
			this.props.onChange(Object.assign(this.props.data, { content: { ...this.props.data.content, [field]: value } }));
		}
	};

	formatDate = date => {
		return moment(date).format('DD.MM.YYYY');
	};

	formatTime = date => {
		return moment(date).format('HH:mm');
	};

	searchSpotify = (i, id) => {
		id = id.replace('spotify:track:', '');

		const options = idx(this.props, _ => _.data.content.options[i]) || {};

		if (id.length > 0) {
			fetch(`${config.SERVICE.musicquery}/get_metadata_by_spotifyid/${id}`)
				.then(res => res.json())
				.then(res => {
					const { mp3SnippetUrl, title, artist } = res.msg;
					this.props.onChange(
						Object.assign(options, {
							teaser: mp3SnippetUrl,
							songtitle: title,
							artist,
							id,
							title: options.title || '',
						})
					);
				})
				.catch(() => {});
		}
	};

	buildSongTitle = i => {
		const options = idx(this.props, _ => _.data.content.options[i]) || {};

		const title = options.songtitle || null;
		const artist = options.artist || null;

		if (title && artist) {
			return `${title} - ${artist}`;
		}
		return title || artist || '';
	};

	render() {
		return (
			<div>
				<h2>Enddatum</h2>
				<muip.DateTimePicker
					name="songbattle-enddate"
					format="D.M.YYYY HH:mm"
					ampm={false}
					onChange={value => this.onChange('endDate', -1, value)}
					value={idx(this.props, _ => _.data.content.endDate) || new Date()}
				/>
				<mui.TextField
					label="Nachricht"
					placeholder="Nachricht nach beendetem Voting."
					fullWidth={true}
					margin="normal"
					value={this.props.data.content.finished}
					onChange={e => this.onChange('finished', -1, e.target.value)}
				/>
				<mui.Grid container spacing={3}>
					{Array.apply(null, Array(NUMBER_OF_SONGS)).map((a, i) => {
						const options = idx(this.props, _ => _.data.content.options[i]) || {};

						return (
							<mui.Grid item sm={6} xs={12} key={i}>
								<h2>Song {i + 1}</h2>
								<mui.TextField
									label="Titel"
									value={options.title || ''}
									onChange={e => this.onChange('title', i, e.target.value)}
									style={{ marginRight: '1rem', width: '50%' }}
								/>
								<mui.TextField
									label="Spotify-ID"
									defaultValue={options.id || ''}
									onChange={e => {
										this.searchSpotify(i, e.target.value);
									}}
								/>
								<br />
								{this.buildSongTitle(i)}
							</mui.Grid>
						);
					})}
				</mui.Grid>
			</div>
		);
	}
}
